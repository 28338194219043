import React from "react"

const viewportContext = React.createContext({});

export const ViewportProvider = ({ children }) => {
    // This is the exact same logic that we previously had in our hook

    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    React.useEffect(() => {
        console.log("ViewPort hook is triggered")
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    /* Now we are dealing with a context instead of a Hook, so instead
       of returning the width and height we store the values in the
       value of the Provider */
    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export const UseViewport = () => {
    /* We can use the "useContext" Hook to access a context from within
       another Hook, remember, Hooks are composable! */
    const { width } = React.useContext(viewportContext);
    return getBreakpoint(width);
}

const getBreakpoint = (width) => {
    if (width <= 600)   return 'xsmall'     // all mobile devices
    if (width <= 960)   return 'small'      // ipad, ipad pro, ipad mini, etc
    if (width <= 1280)   return 'medium'    // small screens, laptops
    if (width <= 1920)  return 'large'      // desktops, large screens

    return 'xlarge'                         // extra large screens, TV
}
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles} from '@material-ui/core/styles';
import {Container, Menu} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import MenuItem from "@material-ui/core/MenuItem";
import ChangePassword from "../ChangePassword";
import MainPage from "./MainPage";

import logo from "../../images/logo.png";

const drawerWidth = 240;

const views = {
    CHANGE_PASSWORD: 'changePassword',
    HOME_ADMIN_PAGE: 'homeAdminPage',
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            zIndex: theme.zIndex.drawer + 1,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        height: "35px",
        width: "120px",
    }
}));

export default function HomePage(props) {
    const { window, userId, handleLogout } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [view, setView] = React.useState(views.HOME_ADMIN_PAGE);
    const userMenuOpen = Boolean(anchorEl);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChangePasswordClicked = () => {
        setView(views.CHANGE_PASSWORD);
        setAnchorEl(null);
    }

    const handleHomeClicked = () => {
        setView(views.HOME_ADMIN_PAGE);
        setAnchorEl(null);
    }

    const getViewContent = () => {
        switch(view) {
            case views.CHANGE_PASSWORD:
                return <ChangePassword/>;
            default:
                return (
                    <MainPage
                        window={window}
                        userId={userId}
                        mobileOpen={mobileOpen}
                        handleDrawerToggle={handleDrawerToggle}
                    />
                )
        }
    };

    return (
        <div>
            <div className={classes.root} id="outer_div">
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} width="100px">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={logo} alt="Logo" className={classes.logo} />
                        <div className={classes.grow} />
                        <div>
                            <IconButton
                                component="a"
                                href="mailto:info@instaspoon.com"
                                color="inherit"
                            >
                                <ContactSupportIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleHomeClicked}
                                color="inherit"
                            >
                                <HomeIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleUserMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={userMenuOpen}
                                onClose={handleUserMenuClose}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                <MenuItem onClick={handleChangePasswordClicked}>Change Password</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <Container>
                    {getViewContent()}
                </Container>
            </div>
        </div>
    );
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const DialogMessages = {
    Location: "This will delete the location and all of its menus",
    Menu: "This will delete the menu and all of its sections",
    Section: "This will delete section and all of its items",
}

export default function DeleteDialog(props) {
    const {itemType, itemName, deleteItem, openDialog, handleDialogClose} = props

    const handleDelete = () => {
        switch (itemType) {
            case "Location":
                deleteItem()
                break
            case "Menu":
                deleteItem()
                break
            case "Section":
                deleteItem()
                break
            case "Item":
                deleteItem()
                break
            default:
                console.error("unsupported item type: ", itemType)
        }

        handleDialogClose()
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>{`Delete ${itemType}`}</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container direction="column">
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                Are you sure you want to delete {itemType} {itemName}?
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                {DialogMessages[itemType]}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="space-evenly">
                        <Grid item>
                            <Button onClick={handleDelete} color="primary">
                                Delete
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleDialogClose} color="primary">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginBottom: 10,
    },
    media: {
        height: 140,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.25),
        },
    },
    collapsableContent: {
        paddingTop: 0,
    },
}));

export default function Item(props) {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const currencySign = '$';

    const tags = props.itemBody.tags?.map(tag => {
        return <Chip size="small" label={tag} variant="outlined" color="primary" />;
    });

    const basePrice = props.itemBody.basePrice ?
        <Chip size="small" label={currencySign + props.itemBody.basePrice} /> : null;

    const additionalPrices = props.itemBody.additionalPrices?.length?
        props.itemBody.additionalPrices.map(ap => {
            return <Grid container justify="flex-end" spacing="1">
                <Grid item>
                    <Typography gutterBottom variant="subtitle2" component="p">
                        {ap.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Chip size="small" label={currencySign + ap.price} />
                </Grid>
            </Grid>
        }) : null;

    const commaJoinedIngredientStr = props.itemBody.ingredients?.join(', ');
    const lastComma = commaJoinedIngredientStr.lastIndexOf(',');
    const ingredients = (lastComma < 0 ? commaJoinedIngredientStr :
                        commaJoinedIngredientStr.substring(0, lastComma) + ' and' + 
                            commaJoinedIngredientStr.substring(lastComma + 1)
                        ) + '.';

    const ingredientContainer = props.itemBody.ingredients?.length?
        <Grid container justify="flex-start" spacing="0" direction="column">
            <Grid item>
                <Typography gutterBottom variant="overline">
                    Ingredients
                </Typography>
            </Grid>
            <Grid item className={classes.chips}>
                <Typography gutterBottom variant="body2">
                    {ingredients}
                </Typography>
            </Grid>
        </Grid> : null;

    const collapsableContent = additionalPrices || ingredientContainer ?
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.collapsableContent}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {additionalPrices}
                </Typography>
                {ingredientContainer && additionalPrices && <Divider/>}
                {ingredientContainer}
            </CardContent>
        </Collapse> : null;

    const collapseButton = collapsableContent ?
        <IconButton
            className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
        >
            <ExpandMoreIcon />
        </IconButton> : null;

    const hasImage = props.itemBody.basicInfo.image.url? true: false;

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <Grid container justify="space-between" spacing={1}>
                        <Grid item>
                            <Typography gutterBottom variant="subtitle1" component="h2">
                                {props.itemBody.basicInfo.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {basePrice}
                        </Grid>
                    </Grid>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {props.itemBody.basicInfo.description}
                    </Typography>
                </CardContent>
                {
                    hasImage &&
                    <CardMedia
                        className={classes.media}
                        image={props.itemBody.basicInfo.image.url}
                        title={props.itemBody.basicInfo.name}
                    />
                }
                <CardActions>
                    <Grid container justify="space-between">
                        <Grid className={classes.chips} item>
                            {tags}
                        </Grid>
                        <Grid item>
                            {collapseButton}
                        </Grid>
                    </Grid>
                </CardActions>
                {collapsableContent}
            </CardActionArea>
        </Card>
    );
}
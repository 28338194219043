import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import NoMenuImage from '../images/no-menu.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        textAlign: 'center',
    },
    media: {
        height: '100%',
        paddingTop: '54.25%', // 16:9
    },
}));

export default function HiddenLocation() {
    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <CardHeader
                title="We are working on our menu"
                subheader="Please come back later!"
            />
            <CardMedia
                className={classes.media}
                image={NoMenuImage}
                title="No menu available"
            />
        </Card>
    );
}
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import DocumentEditor from "./DocumentEditor";
import DeleteDialog from "./DeleteDialog";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import HideDialog from "./HideDialog";
import { deleteItemImage } from "../../util/image-utils";
import MoveArray from "../../util/array-manipulation";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {ListItemIcon, ListItemText, MenuItem, Tooltip} from "@material-ui/core";
import MoveDialog from "./MoveDialog";
import ListIcon from "@material-ui/icons/List";
import Menu from "@material-ui/core/Menu";
import EditIcon from "@material-ui/icons/Edit";
import PanToolIcon from "@material-ui/icons/PanTool";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(8),
    },
    itemMargin: {
        marginLeft: 6
    },
    green: {
        color: 'green',
    },
    darkSelection: {
        backgroundColor: "#ebebeb",
        color: "#3f50b5",
    },
    lightSelection:{
        backgroundColor: "#ededed",
        color: "#3f50b5",
    },
    lightestSelection:{
        backgroundColor: "#f2f0f0",
        color: "#3f50b5",
    },
    icon: {
        minWidth: '30px',
    }
}));

export function ItemContentEditor(props) {
    const classes = useStyles();

    const {docIds, location, updateLocation, moveArrowsDisabled} = props

    const {locationId, menuId, sectionId, itemId} = docIds

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionsClose = () => {
        setAnchorEl(null);
    }

    const handleEditAction = () => {
        handleActionsClose()
        setDisplayItemEditor(true)
    }

    const handleDeleteAction = () => {
        handleActionsClose()
        setDisplayItemDeleteDialog(true)
    }

    const handleMoveAction = () => {
        handleActionsClose()
        setDisplayItemMoveDialog(true)
    }

    const deleteItem = () => {
        let newLocation = JSON.parse(JSON.stringify(location))

        deleteItemImage(location.menus[menuId].sections[sectionId].items[itemId])

        //delete item object
        delete newLocation.menus[menuId].sections[sectionId].items[itemId]

        // delete item id
        const index = newLocation.menus[menuId].sections[sectionId].itemList.indexOf(itemId);
        if (index > -1) {
            newLocation.menus[menuId].sections[sectionId].itemList.splice(index, 1);
        }

        updateLocation(newLocation, ()=>{}, locationId)
    }

    const hideItem = (visibility) => {
        location.menus[menuId].sections[sectionId].items[itemId].visibility = visibility
        updateLocation(location, ()=>{}, locationId)
    }

    const unhideItem = () => {
        location.menus[menuId].sections[sectionId].items[itemId].visibility = {
            "hidden": false,
            "hiddenUntil": ""
        }
        updateLocation(location, ()=>{}, locationId)
    }

    const toggleItemVisibility = () => {
        location.menus[menuId].sections[sectionId].items[itemId].visibility.hidden?
            unhideItem()
            :
            setDisplayItemHideDialog(true)
    }

    const moveItem = (shiftBy) => {
        let newLocation = JSON.parse(JSON.stringify(location))

        MoveArray(newLocation.menus[menuId].sections[sectionId].itemList, itemId, shiftBy)

        updateLocation(newLocation, ()=>{}, locationId)
    }

    const [displayItemEditor, setDisplayItemEditor] = React.useState(false)
    const [displayItemDeleteDialog, setDisplayItemDeleteDialog] = React.useState(false)
    const [displayItemHideDialog, setDisplayItemHideDialog] = React.useState(false)
    const [displayItemMoveDialog, setDisplayItemMoveDialog] = React.useState(false)

    return (
        <Grid container className={classes.itemMargin}>
            <Grid container item alignItems="center" spacing={2}>
                <Grid item>
                    <Chip label="i" color="primary" variant="outlined" size="small"/>
                </Grid>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="body2">
                        {location.menus[menuId].sections[sectionId].items[itemId].basicInfo.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={3} alignItems="center" justify="center">
                <Grid item>
                    <Tooltip title="Move Up" arrow interactive>
                        <IconButton size="small" color="primary" onClick={() => moveItem(-1)} disabled={moveArrowsDisabled.up}>
                            <ArrowUpwardIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Move Down" arrow interactive>
                        <IconButton size="small" color="primary" onClick={() => moveItem(+1)} disabled={moveArrowsDisabled.down}>
                            <ArrowDownwardIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {location.menus[menuId].sections[sectionId].items[itemId].visibility.hidden?
                        <Tooltip title="Unhide" arrow interactive>
                            <IconButton size="large" color="secondary" onClick={toggleItemVisibility}>
                                <VisibilityOffIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Hide" arrow interactive>
                            <IconButton size="large" color="primary" onClick={toggleItemVisibility}>
                                <VisibilityIcon/>
                            </IconButton>
                        </Tooltip>}
                    <HideDialog
                        itemType="Item"
                        hideItem={hideItem}
                        itemName={location.menus[menuId].sections[sectionId].items[itemId].basicInfo.name}
                        openDialog={displayItemHideDialog}
                        handleDialogClose={() => setDisplayItemHideDialog(false)}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        color="primary"
                        onClick={handleActionsClick}
                        size="medium"
                    >
                        <ListIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleActionsClose}
                    >
                        <MenuItem color="primary" onClick={handleEditAction}>
                            <ListItemIcon className={classes.icon}>
                                <EditIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Edit Item</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleMoveAction}>
                            <ListItemIcon className={classes.icon}>
                                <PanToolIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Move Item</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleDeleteAction}>
                            <ListItemIcon className={classes.icon}>
                                <DeleteIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Delete Item</Typography>}/>
                        </MenuItem>
                    </Menu>
                    <DocumentEditor
                        openEditor={displayItemEditor}
                        handleEditorClose={() => setDisplayItemEditor(false)}
                        location={location}
                        updateLocation={updateLocation}
                        docIds={docIds}
                        item={location.menus[menuId].sections[sectionId].items[itemId]}
                        type="Item"
                        editMode={true}
                    />
                    <DeleteDialog
                        deleteItem={deleteItem}
                        itemType="Item"
                        itemName={location.menus[menuId].sections[sectionId].items[itemId].basicInfo.name}
                        openDialog={displayItemDeleteDialog}
                        handleDialogClose={() => setDisplayItemDeleteDialog(false)}
                    />
                    <MoveDialog
                        location={location}
                        updateLocation={updateLocation}
                        docIds={docIds}
                        entityType="Item"
                        openDialog={displayItemMoveDialog}
                        handleDialogClose={() => setDisplayItemMoveDialog(false)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from "@material-ui/core/Grid";
import {TextField} from "formik-material-ui";
import {Field, Form, Formik} from "formik";
import Typography from "@material-ui/core/Typography";
import {IsLocationAliasTaken} from "./LocationManager";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CancelIcon from '@material-ui/icons/Cancel';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {ClickAwayListener, Tooltip} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import QRCode from "easyqrcodejs";
import DialogTitle from "../DialogTitle";
import QrBackgroundImg from "../../images/spoon-logo.png"
import {UseViewport} from "./UseViewport";

export default function LocationAliasDialog(props) {
    const {
        originalLocationAlias,
        editLocationAlias,
        openDialog,
        handleDialogClose
    } = props

    const [openCopiedTooltip, setOpenCopiedTooltip] = React.useState(false);
    const [currentLocationAlias, setCurrentLocationAlias] = React.useState("");
    const [locationQRCode, setLocationQRCode] = React.useState(null)
    const [afterRender, setAfterRender] = React.useState(false)
    const [windowSize] = React.useState(UseViewport())
    const [disallowSave, setDisallowSave] = React.useState(false)

    useEffect(() => {
        setCurrentLocationAlias(originalLocationAlias)
        if (openDialog) {
            setAfterRender(true)
        }

    }, [openDialog, originalLocationAlias])

    const doUpdateLocationAlias = newLocationAlias => {
        setCurrentLocationAlias(newLocationAlias)
        setAfterRender(true)
    }

    // Update the location's QR code every time the location's alias changes
    useEffect(() => {
        if (!afterRender) return

        if (locationQRCode) locationQRCode.clear()

        const getQRCodeDimensions = () => {
            switch (windowSize) {
                case 'xsmall':
                    return { width: 200, height: 200 }

                case 'small':
                case 'medium':
                    return { width: 300, height: 300 }

                default:
                    return { width: 400, height: 400 }
            }
        }

        let newLocationQRCode = new QRCode(document.getElementById("qrcode"), {
            text: `https://instaspoon.com/${currentLocationAlias}`,
            ...getQRCodeDimensions(),
            correctLevel : QRCode.CorrectLevel.M, // L, M, Q, H

            // ====== dotScale
            dotScale: 0.4, // For body block, must be greater than 0, less than or equal to 1. default is 1


            // ====== Background Image
            backgroundImage: QrBackgroundImg, // Background Image
            backgroundImageAlpha: 0.5, // Background image transparency, value between 0 and 1. default is 1.
            autoColor: true, // Automatic color adjustment(for data block)


            // ====== Colorful
            // === Position Pattern(Eye) Color
            PO: '#665B86', // Global Position Outer color. if not set, the default is `colorDark`
            PI: '#5EA165', // Global Position Inner color. if not set, the default is `colorDark`
            PO_TL:'#665B86', // Position Outer color - Top Left
            PI_TL:'#5EA165', // Position Inner color - Top Left
            PO_TR:'#665B86', // Position Outer color - Top Right
            PI_TR:'#5EA165', // Position Inner color - Top Right
            PO_BL:'#665B86', // Position Outer color - Bottom Left
            PI_BL:'#5EA165', // Position Inner color - Bottom Left

            // === Alignment Color
            AO: '#665B86', // Alignment Outer. if not set, the default is `colorDark`
            AI: '#5EA165', // Alignment Inner. if not set, the default is `colorDark`

            // === Timing Pattern Color
            timing_H: '#665B86', // Horizontal timing color
            timing_V: '#665B86', // Vertical timing color

            // ====== Title
            title: 'Made with ❤️  by Instaspoon', // content
            titleFont: "bold 12px Arial", //font. default is "bold 16px Arial"
            titleColor: "#000000", // color. default is "#000000"
            titleBackgroundColor: "#fff", // background color. default is "#fff"
            titleHeight: 30, // height, including subTitle. default is 0
            titleTop: 15, // draws y coordinates. default is 30
        })

        setLocationQRCode(newLocationQRCode)
        setAfterRender(false)

    }, [afterRender, locationQRCode, windowSize, currentLocationAlias])

    const validate = (values) => {
        let errors = {}

        if (!values.locationAlias) {
            errors.locationAlias = 'Required';
        } else if (!/^[a-zA-Z0-9._~-]{1,50}$/i.test(values.locationAlias)) {
            errors.locationAlias = 'Only alpha-numeric and -, ., _, ~ characters are allowed';
        }

        return errors
    }

    const handleCopiedTooltipClose = () => {
        setOpenCopiedTooltip(false);
    }

    const handleCopiedTooltipOpen = () => {
        setOpenCopiedTooltip(true);
    }

    const getDirectLink = () => {
        return `https://instaspoon.com/${currentLocationAlias}`
    }

    const downloadQRCode = () => {
        let div = document.getElementById("qrcode")
        let canvas = div.firstChild
        let image = canvas.toDataURL("image/jpeg", 1).replace("image/jpeg", "image/octet-stream")
        let link = document.createElement('a')
        link.download = "location-qr-code.jpeg"
        link.href = image
        link.click()
    }

    const saveQRCode = (formProps) => {
        if (!formProps.isValid) return

        if (formProps.values.locationAlias === currentLocationAlias) {
            formProps.setFieldValue('editMode', false)
            return
        }

        setDisallowSave(true)

        IsLocationAliasTaken(formProps.values.locationAlias)
            .then(snapshot => {
                if (!snapshot.exists) {
                    editLocationAlias(formProps.values.locationAlias,
                        ()=> {
                            doUpdateLocationAlias(formProps.values.locationAlias)
                            formProps.setFieldValue('editMode', false)
                        })
                }
                setDisallowSave(false)
            })
            .catch(error => {
                console.log(`data access error; failed verify location alias ${error}`)
                setDisallowSave(false)
                return { locationAlias: 'Failed to verify location direct link' }
            })

    }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                fullWidth={true}
                maxWidth="sm"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle onClose={handleDialogClose}>
                    Location Direct Links
                </DialogTitle>
                <DialogContent dividers>
                    <Formik
                        initialValues={{
                            locationAlias: originalLocationAlias,
                            editMode: false
                        }}
                        validate={validate}
                        validateOnChange={false}
                        validateOnBlur={true}
                    >
                        {formProps => {
                            return (
                                <Form>
                                    <Grid container alignItems="center" justify="center" spacing={7}>
                                        <Grid container item alignItems="center" justify="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle1" align="center">
                                                    Customize you location's direct link and share the QR code
                                                    to make it easily accessible by your customers.
                                                </Typography>
                                            </Grid>
                                            <Grid container item alignItems="center" justify="center">
                                                <Grid item>
                                                    {
                                                        formProps.values.editMode &&
                                                        <Grid item>
                                                            <Typography variant="caption" color="secondary">
                                                                Changes take effect immediately
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                    <Field
                                                        component={TextField}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Direct Link"
                                                        name="locationAlias"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">instaspoon.com/</InputAdornment>,
                                                        }}
                                                        disabled={!formProps.values.editMode}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container item justify="center" spacing={5}>
                                                <Grid item>
                                                    {
                                                        formProps.values.editMode?
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                startIcon={<CancelIcon/>}
                                                                onClick={() => {
                                                                    formProps.setFieldValue('locationAlias', currentLocationAlias)
                                                                    formProps.setFieldValue('editMode', false)
                                                                    formProps.setErrors({})
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            :
                                                            <ClickAwayListener onClickAway={handleCopiedTooltipClose}>
                                                                <div>
                                                                    <Tooltip
                                                                        PopperProps={{
                                                                            disablePortal: true,
                                                                        }}
                                                                        onClose={handleCopiedTooltipClose}
                                                                        open={openCopiedTooltip}
                                                                        disableFocusListener
                                                                        disableHoverListener
                                                                        disableTouchListener
                                                                        title="Copied to clipboard."
                                                                    >
                                                                        <CopyToClipboard
                                                                            text={getDirectLink()}
                                                                            onCopy={handleCopiedTooltipOpen}
                                                                        >
                                                                            <Button
                                                                                color="primary"
                                                                                variant="outlined"
                                                                                disabled={formProps.values.editMode}
                                                                                startIcon={<FileCopyIcon/>}
                                                                            >
                                                                                Copy
                                                                            </Button>
                                                                        </CopyToClipboard>
                                                                    </Tooltip>
                                                                </div>
                                                            </ClickAwayListener>

                                                    }
                                                </Grid>
                                                <Grid item>
                                                    {
                                                        formProps.values.editMode?
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                startIcon={<SaveIcon />}
                                                                onClick={() => saveQRCode(formProps)}
                                                                disabled={disallowSave}
                                                            >
                                                                Save
                                                            </Button>
                                                            :
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    formProps.setFieldValue('editMode', true)
                                                                }}
                                                                startIcon={<EditIcon/>}
                                                            >
                                                                Edit
                                                            </Button>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container item alignItems="center" justify="center" spacing={2} direction="column">
                                            <Grid item>
                                                <div id="qrcode"></div>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    startIcon={<CloudDownloadIcon/>}
                                                    onClick={downloadQRCode}
                                                >
                                                    Download
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}

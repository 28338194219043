import React from "react"

import {
    Backdrop,
    Checkbox,
    CircularProgress, ClickAwayListener,
    Dialog,
    DialogContent, Divider,
    Input,
    InputLabel,
    MenuItem, Tooltip
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Grid, Typography} from "@material-ui/core";
import {List, ListItem, ListItemText, ListItemSecondaryAction} from "@material-ui/core"

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from "@material-ui/core/IconButton";

import {Field, Form, Formik} from "formik";
import {Select, TextField} from "formik-material-ui";

import DialogTitle from "./../DialogTitle";
import {ItemValidationSchema} from "../../util/validation";
import InputAdornment from "@material-ui/core/InputAdornment";
import ImageManager from "./ImageManager";
import { deleteDraftImage, uploadDraftImage } from "../../util/image-utils";
import {Dietary, Spicy} from "../../util/consts";
import FormControl from "@material-ui/core/FormControl";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formElement: {
        marginBottom: 20
    },
    timeElement: {
        marginRight: 10
    },
    addIngredientButton: {
        marginTop: 15,
        height: 55,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    textfieldStyle: {
        width: "100%",
        minWidth: "250px"
    },
}));

export default function ItemEditor(props) {
    const classes = useStyles();

    const {userId, locationId, itemId} = props.docIds

    const [displayProgressBar, setDisplayProgressBar] = React.useState(false)

    const updateState = () => {
        setDisplayProgressBar(false)
        props.handleItemEditorClose()
    }

    const saveItemStateful = (item, image) => {
        setDisplayProgressBar(true)
        props.setItem(item, image, updateState)
    }

    const onSubmit = formData => {
        let item = formData.base
        let image = formData.image.data
        let imagePath = formData.image.path

        switch (image.status) {
            case "UNTOUCHED":
                saveItemStateful(item, {
                    url: image.url,
                    path: imagePath
                });
                break;

            case "UPDATED":
                uploadDraftImage(userId, locationId, 'items', image.file, itemId, item, saveItemStateful);
                break;

            case "DELETED":
                deleteDraftImage(imagePath, item, saveItemStateful);
                break;

            default:
                console.error("unexpected image status ", image.status);
        }
    }

    const [openLiveTooltip, setOpenLiveTooltip] = React.useState(false);

    const handleLiveTooltipClose = () => {
        setOpenLiveTooltip(false);
    };

    const handleLiveTooltipOpen = () => {
        setOpenLiveTooltip(true);
    };

    return(
        <div>
            <Dialog
                open={props.openItemEditor}
                onClose={props.handleItemEditorClose}
                fullWidth={true}
                maxWidth="md"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle onClose={props.handleItemEditorClose}>
                    Item Editor
                </DialogTitle>
                <DialogContent dividers>
                    <Backdrop className={classes.backdrop} open={displayProgressBar}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Formik
                        initialValues={{
                            base: props.item,
                            image: {
                                path: props.item.basicInfo.image.path,
                                data: {
                                    status: "UNTOUCHED",
                                    url: props.item.basicInfo.image.url,
                                    file: null,
                                }
                            },
                            tag: "",
                            ingredient: "",
                            additionalPrice: {
                                name: "",
                                price: "",
                            }
                        }}
                        onSubmit={onSubmit}
                        validationSchema={ItemValidationSchema}
                    >
                        {formProps => {
                            return (
                                <Form className={classes.form}>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Name"
                                        name="base.basicInfo.name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        className={classes.formElement}
                                    />
                                    <Field
                                        component={TextField}
                                        multiline
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Description"
                                        name="base.basicInfo.description"
                                        rows={4}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Divider className={classes.formElement}/>
                                    <Grid container className={classes.formElement}>
                                        <Grid item>
                                            <Typography variant="h6" color="primary">
                                                Price
                                            </Typography>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Price"
                                                    name="base.basePrice"
                                                    type="number"
                                                    rows={4}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item container>
                                                <Grid item container alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="subtitle1" color="primary">
                                                            Custom Prices
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <ClickAwayListener onClickAway={handleLiveTooltipClose}>
                                                            <div>
                                                                <Tooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    onClose={handleLiveTooltipClose}
                                                                    open={openLiveTooltip}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title="Custom price to account for the item's different sizes, varieties, add-ons etc."
                                                                >
                                                                    <IconButton onClick={handleLiveTooltipOpen} color="primary">
                                                                        <InfoOutlinedIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </ClickAwayListener>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container spacing={2} alignItems="center">
                                                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                        <Field
                                                            component={TextField}
                                                            name="additionalPrice.name"
                                                            label="Category"
                                                            type="search"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                        <Field
                                                            component={TextField}
                                                            name="additionalPrice.price"
                                                            label="Price"
                                                            type="number"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            size="small"
                                                            onClick={
                                                                () => {
                                                                    if (
                                                                        formProps.values.additionalPrice.price >= 0 &&
                                                                        formProps.values.additionalPrice.name.trim() &&
                                                                        formProps.values.base.additionalPrices.length < 10)
                                                                    {
                                                                        formProps.setFieldValue("base.additionalPrices",
                                                                            [...formProps.values.base.additionalPrices, {...formProps.values.additionalPrice}])
                                                                        formProps.setFieldValue("additionalPrice", {name: "", price: ""})
                                                                    }
                                                                }
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <List>
                                                        {formProps.values.base.additionalPrices.map((additionalPrice) => {
                                                                return (
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={`${additionalPrice.name}   :   $${additionalPrice.price}`}
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton
                                                                                color="secondary"
                                                                                edge="end"
                                                                                onClick={
                                                                                    ()=> formProps.setFieldValue("base.additionalPrices", formProps.values.base.additionalPrices.filter(
                                                                                        other => other.name !== additionalPrice.name && other.price !== additionalPrice.price
                                                                                    ))
                                                                                }
                                                                            >
                                                                                <HighlightOffIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                )
                                                            }
                                                        )}
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider className={classes.formElement}/>
                                    <Grid container className={classes.formElement}>
                                        <Grid item>
                                            <Typography variant="h6" color="primary">
                                                Ingredients
                                            </Typography>
                                        </Grid>
                                        <Grid container item spacing={1} alignItems="center">
                                            <Grid item xs={9} sm={9} md={9}>
                                                <Field
                                                    component={TextField}
                                                    fullWidth
                                                    type="search"
                                                    label="Ingredient"
                                                    name="ingredient"
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.addIngredientButton}
                                                    onClick={
                                                        () => {
                                                            formProps.values.ingredient.trim() &&
                                                            formProps.values.base.ingredients.length < 10 &&
                                                            formProps.setFieldValue("base.ingredients", [...formProps.values.base.ingredients, formProps.values.ingredient])
                                                            formProps.setFieldValue("ingredient", "")
                                                        }
                                                    }
                                                >
                                                    Add
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <List>
                                                {formProps.values.base.ingredients.map((ingredient) => {
                                                        return (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={ingredient}
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        color="secondary"
                                                                        onClick={
                                                                            ()=> formProps.setFieldValue("base.ingredients", formProps.values.base.ingredients.filter(other => other!==ingredient))
                                                                        }
                                                                    >
                                                                        <HighlightOffIcon fontSize="small" />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        )
                                                    }
                                                )}
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Divider className={classes.formElement}/>
                                    <Grid container className={classes.formElement}>
                                        <Grid item className={classes.formElement}>
                                            <Typography variant="h6" color="primary">
                                                Tags
                                            </Typography>
                                        </Grid>
                                        <Grid container item spacing={3} direction="column">
                                            <Grid item xs={6} sm={6} md={6}>
                                                <FormControl className={classes.textfieldStyle}>
                                                    <InputLabel shrink>Dietary Preferences</InputLabel>
                                                    <Field
                                                        className={classes.textfieldStyle}
                                                        label="Dietary Preferences"
                                                        component={Select}
                                                        type="text"
                                                        select={true}
                                                        name={`base.tags`}
                                                        multiple
                                                        input={<Input />}
                                                        renderValue={(selected) => selected.filter(entity => Dietary.includes(entity)).join(', ')}
                                                    >
                                                        {Dietary.map(dietaryChoice =>
                                                            <MenuItem key={dietaryChoice} value={dietaryChoice}>
                                                                <Checkbox color="primary" checked={formProps.values.base.tags.indexOf(dietaryChoice) > -1} />
                                                                <ListItemText primary={dietaryChoice} />
                                                            </MenuItem>
                                                        )}
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <FormControl className={classes.textfieldStyle}>
                                                    <InputLabel shrink>Spice Levels</InputLabel>
                                                    <Field
                                                        className={classes.textfieldStyle}
                                                        component={Select}
                                                        type="text"
                                                        select={true}
                                                        name={`base.tags`}
                                                        multiple
                                                        input={<Input />}
                                                        renderValue={(selected) => selected.filter(entity => Spicy.includes(entity)).join(', ')}
                                                    >
                                                        {Spicy.map(spiceChoice =>
                                                            <MenuItem key={spiceChoice} value={spiceChoice}>
                                                                <Checkbox color="primary" checked={formProps.values.base.tags.indexOf(spiceChoice) > -1} />
                                                                <ListItemText primary={spiceChoice} />
                                                            </MenuItem>
                                                        )}
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider className={classes.formElement}/>
                                    <grid item xs={12} style={{
                                        margin: 'auto',
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        marginBottom: 20
                                    }}>
                                        <ImageManager
                                            imageURL={formProps.values.image.data.url}
                                            updateImage={(imgFile, imgURL) => {
                                                formProps.setFieldValue("image.data", {
                                                    status: "UPDATED",
                                                    url: imgURL,
                                                    file: imgFile
                                                })
                                            }}
                                            deleteImage={() => formProps.setFieldValue("image.data", {status: "DELETED"})}
                                        />
                                    </grid>
                                    <Grid container spacing={2} className={classes.formElement} xs={12}>
                                        <Grid item xs={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={props.handleItemEditorClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}
import React, {useEffect} from 'react';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AppBar, Backdrop, Container, createMuiTheme, CssBaseline, Toolbar, Typography} from '@material-ui/core';
import { firebase, analytics } from '../util/firebase-client-utils';
import "firebase/auth";
import HomePage from "../components/admin/HomePage";
import logo from "../images/logo.png";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    logo: {
        height: "35px",
        width: "120px",
    }
}));

const ADMIN_THEME = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#4377b9',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#9a54de',
        },
    },
    typography: {
        "fontFamily": `'Roboto', sans-serif;`,
    },
});

const views = {
    LOADING_VIEW: "loadingView",
    LOGGED_IN_VIEW: 'loggedInView',
    LOGGED_OUT_VIEW: 'loggedOutView',
}

export default function Admin(props) {
    const classes = useStyles();

    const [loggedInUser, setLoggedInUser] = React.useState(null)
    const [view, setView] = React.useState(views.LOADING_VIEW)

    const handleLogout = (e) => {
        e.preventDefault();
        firebase.auth().signOut();
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setLoggedInUser(user.uid)
                setView(views.LOGGED_IN_VIEW)
            } else {
                setLoggedInUser(null)
                setView(views.LOGGED_OUT_VIEW)
            }
        })
    }, [])

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        //signInFlow: 'popup',
        // Redirect to / after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/admin',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                setLoggedInUser(firebase.auth().currentUser);
                analytics.logEvent('login', {
                    user: authResult.getUser().getDisplayName(),
                    provider: authResult.getAdditionalUserInfo().getProviderId(),
                });
                return false;
            },
            uiShown: function () {
                // The widget is rendered.
                // Hide the loader.
                document.getElementById('loader').style.display = 'none';
            }
        }
    };

    const getLoggedOutView = () => {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="static">
                    <Toolbar>
                        <img src={logo} alt="Logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
                <br/>
                <Container>
                    <>
                        <div id="loader"><CircularProgress /></div>
                        <Grid container item justify="center" alignItems="center" direction="column" spacing={3}>
                            <Grid item>
                                <Typography variant="h6">
                                    Sign up or log in
                                </Typography>
                            </Grid>
                            <Grid item style={{width:"100%"}}>
                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                            </Grid>
                        </Grid>
                    </>
                </Container>
            </div>
        )
    }

    const getLoadingView = () => {
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const getLoggedInView = () => {
        return (
            <HomePage userId={loggedInUser} handleLogout={handleLogout}/>
        )
    }

    const getAdminView = () => {
        switch(view) {
            case views.LOADING_VIEW:
                return getLoadingView()
            case views.LOGGED_IN_VIEW:
                return getLoggedInView()
            default:
                return getLoggedOutView()
        }
    }

    return (
        <MuiThemeProvider theme={ADMIN_THEME}>
            { getAdminView() }
        </MuiThemeProvider>
    )


}
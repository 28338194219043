import {DaysNumeric} from "./validation";

function isAvailable(availability, availabilityType, now) {
    if (availabilityType === "always") return true

    for (let i=0; i<availability.length; i++) {

        let fromTime = availability[i].fromTime
        let toTime = availability[i].toTime
        let days = availability[i].days

        for (let j=0; j<days.length; j++) {

            let convertedSlots = convertSlot(days[j], fromTime, toTime)

            for (let k=0; k<convertedSlots.length; k++) {

                let convertedDay = convertedSlots[k].day
                let convertedFromTime = convertedSlots[k].fromTime
                let convertedToTime = convertedSlots[k].toTime

                if (matchesRange(now, convertedDay, convertedFromTime, convertedToTime)) {
                    return true
                }
            }
        }
    }

    return false
}

function convertSlot(day, fromTime, toTime) {
    let fromTimeOfDay = fromTime.split(" ")[1].toUpperCase()
    let toTimeOfDay = toTime.split(" ")[1].toUpperCase()

    if (fromTimeOfDay === 'PM' && toTimeOfDay === 'AM') {
        let nextDay = (DaysNumeric[day]+1)%7
        return [
            {
                day: DaysNumeric[day],
                fromTime: fromTime,
                toTime: '11:59 PM'
            },
            {
                day: nextDay,
                fromTime: '12:00 AM',
                toTime: toTime
            },
        ]
    }

    return [{
        day: DaysNumeric[day],
        fromTime: fromTime,
        toTime: toTime
    }]
}

function matchesRange(now, day, startTime, endTime) {
    let currentDay = now.getDay();
    let currentHour = now.getHours();
    let currentMinute = now.getMinutes();

    let startTimeDetails = getTimeDetails(startTime)
    let endTimeDetails = getTimeDetails(endTime)

    let timeMatches = currentDay === day &&
        (currentHour > startTimeDetails.hour ||
            (currentHour === startTimeDetails.hour && currentMinute >= startTimeDetails.minute)) &&
        (currentHour < endTimeDetails.hour ||
            (currentHour === endTimeDetails.hour && currentMinute <= endTimeDetails.minute));

    return timeMatches;
}

function getTimeDetails(time) {
    let timeHour = parseInt(time.split(" ")[0].split(":")[0])
    let timeMinute = parseInt(time.split(" ")[0].split(":")[1])
    let timePM = time.split(" ")[1].toUpperCase() === 'PM'

    if (timePM && timeHour !== 12) {
        timeHour += 12;
    }
    if (!timePM && timeHour === 12) {
        timeHour = 0;
    }

    return {
        hour: timeHour,
        minute: timeMinute,
    }
}

function isHidden(visibility, now) {
    return visibility.hiddenUntil?
        visibility.hidden && now <= new Date(visibility.hiddenUntil)
        :
        visibility.hidden
}

/**
 * Entity could be a menu, a section or an item.
 * All these entities have the same fields that determine visibility.
 */
export function isEntityVisible(entity, now) {
    return !isHidden(entity.visibility, now);
}

export function isEntityAvailable(entity, now) {
    return isAvailable(entity.availability, entity.availabilityType, now);
}

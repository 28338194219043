import React from "react"

import { Formik } from "formik";
import { object, ref, string } from "yup";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import { firebase } from '../util/firebase-client-utils';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    progress: {
        position: "fixed",
        overflow: "show",
        margin: "auto",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "50px",
        height: "50px"
    },
    toolbar: theme.mixins.toolbar
}));

export default function ChangePassword() {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogText, setDialogText] = React.useState("");

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    function _handleSubmit({
        currentPass,
        newPass,
        confirmPass,
        setSubmitting,
        resetForm,
      }) {
        var user = firebase.auth().currentUser;
        user.updatePassword(newPass).then(function() {
            setDialogText("Password changed successfully!")
        }).catch(function(error) {
            setDialogText("Password changing failed: " + error.message);
        }).finally(() =>{
            setOpenDialog(true);
            setSubmitting(false);
            resetForm();
        })
      }

    return(
        <div>
            <div className={classes.toolbar} />
            <Formik
                initialValues={{
                    newPass: '',
                    confirmPass: '',
                }}
                validationSchema={object().shape({
                    newPass: string().required('New password is required'),
                    confirmPass: string()
                        .oneOf([ref('newPass')], 'Passwords do not match')
                        .required('Password is required'),
                })}
                onSubmit={(
                    { newPass, confirmPass },
                    { setSubmitting, resetForm }
                ) => {
                    _handleSubmit({
                        newPass,
                        confirmPass,
                        setSubmitting,
                        resetForm,
                    });
                }
                }
                render={props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                    } = props
                    return isSubmitting ? (
                        <CircularProgress className={classes.progress} />
                    ) : (
                        <Grid
                            className={classes.root}
                            container
                            direction="column"
                            alignItems="center"
                            justify="flex-start">
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <FormControl
                                    className={classes.formElement}
                                    margin="dense"
                                    error={Boolean(touched.newPass && errors.newPass)}
                                >
                                    <InputLabel
                                        htmlFor="password-new"
                                        error={Boolean(touched.newPass && errors.newPass)}
                                    >
                                        {'New Password'}
                                    </InputLabel>
                                    <Input
                                        id="password-new"
                                        name="newPass"
                                        type="password"
                                        value={values.newPass}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.newPass && errors.newPass)}
                                    />
                                    <FormHelperText
                                        error={Boolean(touched.newPass && errors.newPass)}
                                    >
                                        {touched.newPass && errors.newPass ? errors.newPass : ''}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    className={classes.formElement}
                                    margin="normal"
                                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                                >
                                    <InputLabel
                                        htmlFor="password-confirm"
                                        error={Boolean(touched.confirmPass && errors.confirmPass)}
                                    >
                                        {'Confirm Password'}
                                    </InputLabel>
                                    <Input
                                        id="password-confirm"
                                        name="confirmPass"
                                        type="password"
                                        value={values.confirmPass}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.confirmPass && errors.confirmPass)}
                                    />
                                    <FormHelperText
                                        error={Boolean(touched.confirmPass && errors.confirmPass)}
                                    >
                                        {touched.confirmPass && errors.confirmPass
                                            ? errors.confirmPass
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                                <br/>
                                <Button
                                    className={classes.formElement}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={Boolean(!isValid || isSubmitting)}
                                >
                                    {'Reset Password'}
                                </Button>
                            </form>
                            <Dialog
                                open={openDialog}
                                onClose={handleCloseDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {dialogText}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    )
                }}
            />
        </div>
    )
}
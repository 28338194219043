import React from "react";
import throttle from "lodash/throttle";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

const tabHeight = 69;
const paddingTop = 0;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    indicator: {
        padding: theme.spacing(1)
    },
    tabsToolbar: {
        backgroundColor: theme.palette.background.paper,
        position: "sticky",
        top: paddingTop,
        left: 0,
        right: 0,
        width: "90%",
        zIndex: 100,
    },
    container: {
        marginTop: 0,
        width: '345px',
    }
}));

/******* This is the scroll spy magic */
/*
Credits: Material UI
Source: 
https://github.com/mui-org/material-ui/blob/404c2ba16816f5c7ab7d8b2caf6bbc3d2218b820/docs/src/modules/utils/textToHash.js
*/
const makeUnique = (hash, unique, i = 1) => {
    const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

    if (!unique[uniqueHash]) {
        unique[uniqueHash] = true;
        return uniqueHash;
    }

    return makeUnique(hash, unique, i + 1);
};

const textToHash = (text, unique = {}) => {
    return makeUnique(
        encodeURI(
            text
                .toLowerCase()
                .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
                // eslint-disable-next-line no-useless-escape
                .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
                .replace(/-+/g, "-")
                .replace(/^-|-$/g, "")
        ),
        unique
    );
};
const noop = () => { };

function useThrottledOnScroll(callback, delay) {
    const throttledCallback = React.useMemo(
        () => (callback ? throttle(callback, delay) : noop),
        [callback, delay]
    );

    React.useEffect(() => {
        if (throttledCallback === noop) return undefined;

        window.addEventListener("scroll", throttledCallback);
        return () => {
            window.removeEventListener("scroll", throttledCallback);
            throttledCallback.cancel();
        };
    }, [throttledCallback]);
}

function ScrollSpyTabs(props) {
    const classes = useStyles();
    const [activeState, setActiveState] = React.useState(null);
    const { tabsInScroll } = props;

    let itemsServer = tabsInScroll.map(tab => {
        const hash = textToHash(tab.text);
        return {
            icon: tab.icon || "",
            text: tab.text,
            component: tab.component,
            hash: hash,
            node: document.getElementById(hash)
        };
    });

    const itemsClientRef = React.useRef([]);
    React.useEffect(() => {
        itemsClientRef.current = itemsServer;
    }, [itemsServer]);

    const clickedRef = React.useRef(false);
    const unsetClickedRef = React.useRef(null);
    const findActiveIndex = React.useCallback(() => {
        // set default if activeState is null
        if (activeState === null) setActiveState(itemsServer[0]?.hash);

        // Don't set the active index based on scroll if a link was just clicked
        if (clickedRef.current) return;

        let active;
        for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
            // No hash if we're near the top of the page
            if (document.documentElement.scrollTop < 0) {
                active = { hash: null };
                break;
            }

            const item = itemsClientRef.current[i];

            if (
                item.node &&
                item.node.offsetTop <
                document.documentElement.scrollTop +
                document.documentElement.clientHeight / 8 +
                tabHeight
            ) {
                active = item;
                break;
            }
        }

        if (active && activeState !== active.hash) {
            setActiveState(active.hash);
        }
    }, [activeState, itemsServer]);

    // 90 is a magic number. Chosen as the max number that still worked when
    // testing fast scrolls up and down on a mobile device.
    useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 90);

    const handleClick = hash => () => {
        // Used to disable findActiveIndex if the page scrolls due to a click
        clickedRef.current = true;
        unsetClickedRef.current = setTimeout(() => {
            clickedRef.current = false;
        }, 1000);

        if (activeState !== hash) {
            setActiveState(hash);

            if (window)
                window.scrollTo({
                    top:
                        document.getElementById(hash).getBoundingClientRect().top +
                        window.pageYOffset - paddingTop - tabHeight,
                    behavior: "smooth"
                });
        }
    };

    React.useEffect(
        () => () => {
            clearTimeout(unsetClickedRef.current);
        },
        []
    );

    return (
        <div className={classes.root}>
            <Toolbar variant="dense" className={classes.tabsToolbar}>
                <Grid container justify="center">
                    <Tabs
                        value={activeState ? activeState : itemsServer[0]?.hash}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons={itemsServer.length <= 1 ? "off" : "on"}
                    >
                        {itemsServer.map(item => (
                            <Tab
                                key={item.hash}
                                label={item.text}
                                onClick={handleClick(item.hash)}
                                value={item.hash}
                            />
                        ))}
                    </Tabs>
                    <div className={classes.indicator} />
                </Grid>
            </Toolbar>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <div className={classes.container}>
                    {itemsServer.map(item => (
                        <article id={item.hash} key={item.text}>
                            {item.component}
                        </article>
                    ))}
                </div>
            </Grid>
        </div>
    );
}

export default ScrollSpyTabs;

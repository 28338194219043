import React from "react"
import Grid from "@material-ui/core/Grid";
import {MenuContentEditor} from "./MenuContentEditor";
import {SectionContentEditor} from "./SectionContentEditor";
import {ItemContentEditor} from "./ItemContentEditor";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiCollapse from "@material-ui/core/Collapse";
import {Paper} from "@material-ui/core";

const GridItemXs12 = props => <Grid item xs={12} {...props} />;

// Custom Collapse so it works with Grid
const Collapse = props => {
    const classes = useCollapseStyles();
    return (
        <MuiCollapse
            component={GridItemXs12}
            classes={{
                hidden: classes.hidden,
                root: classes.root
            }}
            {...props}
        >
            {/* This spacing has to match with the one in the container
            outside the collapse */}
            <Grid container spacing={3}>
                {props.children}
            </Grid>
        </MuiCollapse>
    );
};

const useCollapseStyles = makeStyles({
    root: {
        width: "100%"
    },
    hidden: {
        // The grid item's padding normally balances with the negative padding
        // of the container outside the Collapse.
        // But when the collapse hides its content, the padding of the item
        // is still taking up space, creating an unwanted space.
        // The 'hidden' style rule is only applied when the collapse finishes
        // hiding its content
        padding: "0 !important"
    }
});

const useStyles = makeStyles((theme) => ({
    paperMenu: {
        padding: theme.spacing(2),
        width: "100%",
        backgroundColor: '#ebeff2',
    },
    paperSection: {
        padding: theme.spacing(2),
        width: "100%",
        backgroundColor: '#f5f6f7',
    },
}));

export function LocationContentEditor(props) {
    const classes = useStyles();

    const {docIds, location, updateLocation} = props

    const [collapsedItems, setCollapsedItems] = React.useState(new Set())

    const toggleItem = (itemId) => {
        const newCollapsedItems = new Set(collapsedItems)
        newCollapsedItems.has(itemId)? newCollapsedItems.delete(itemId): newCollapsedItems.add(itemId)
        setCollapsedItems(newCollapsedItems)
    }

    const isExpanded = (itemId) => {
        return !collapsedItems.has(itemId)
    }

    return (
        <Grid container item spacing={4}>
            {
                location.menuList.map((menuId, mIndex) => {
                    return (
                        <Grid container item>
                            <Paper className={classes.paperMenu} elevation={0}>
                                <MenuContentEditor
                                    docIds={{
                                        ...docIds,
                                        menuId: menuId
                                    }}
                                    location={location}
                                    updateLocation={updateLocation}
                                    toggleMenu={toggleItem}
                                    isExpanded={isExpanded}
                                    moveArrowsDisabled={{
                                        up: mIndex === 0,
                                        down: mIndex >= location.menuList.length-1

                                    }}
                                />
                                <Grid container spacing={4}>
                                    <Collapse in={isExpanded(menuId)} timeout="auto" unmountOnExit>
                                        {
                                            location.menus[menuId].sectionList.map((sectionId, sIndex) => {
                                                return (
                                                    <Grid container item>
                                                        <Paper className={classes.paperSection} elevation={0}>
                                                            <SectionContentEditor
                                                                docIds={{
                                                                    ...docIds,
                                                                    menuId: menuId,
                                                                    sectionId: sectionId
                                                                }}
                                                                location={location}
                                                                updateLocation={updateLocation}
                                                                toggleSection={toggleItem}
                                                                isExpanded={isExpanded}
                                                                moveArrowsDisabled={{
                                                                    up: sIndex === 0,
                                                                    down: sIndex >= location.menus[menuId].sectionList.length-1

                                                                }}
                                                            />
                                                            <Grid container spacing={4}>
                                                                <Collapse in={isExpanded(sectionId)} timeout="auto" unmountOnExit>
                                                                    {
                                                                        location.menus[menuId].sections[sectionId].itemList.map((itemId, iIndex) => {
                                                                            return (
                                                                                <Grid container item>
                                                                                    <Paper className={classes.paperMenu} elevation={0}>
                                                                                        <ItemContentEditor
                                                                                            docIds={{
                                                                                                ...docIds,
                                                                                                menuId: menuId,
                                                                                                sectionId: sectionId,
                                                                                                itemId: itemId
                                                                                            }}
                                                                                            location={location}
                                                                                            updateLocation={updateLocation}
                                                                                            moveArrowsDisabled={{
                                                                                                up: iIndex === 0,
                                                                                                down: iIndex >= location.menus[menuId].sections[sectionId].itemList.length-1

                                                                                            }}
                                                                                        />
                                                                                    </Paper>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Collapse>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Collapse>

                                </Grid>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}
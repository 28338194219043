import React from "react"
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import DocumentEditor from "./DocumentEditor";
import DeleteDialog from "./DeleteDialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import HideDialog from "./HideDialog";
import {GetSectionInitialValues} from "./InitialValues";
import uniqid from "uniqid"
import { deleteMenuChildImages } from "../../util/image-utils";
import IconButton from "@material-ui/core/IconButton";
import MoveArray from "../../util/array-manipulation";
import {ListItemIcon, ListItemText, MenuItem, Tooltip} from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import ListIcon from '@material-ui/icons/List';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
    button: {
        cursor: 'pointer',
        transition: theme.transitions.create(['background-color', 'transform'], {
            duration: theme.transitions.duration.standard,
        }),
        '&:hover': {
            transform: 'scale(1.5)',
        },
    },
    paper: {
        padding: theme.spacing(2),
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    darkSelection: {
        backgroundColor: "#ebebeb",
        color: "#3f50b5",
    },
    lightSelection:{
        backgroundColor: "#ededed",
        color: "#3f50b5",
    },
    lightestSelection:{
        backgroundColor: "#f2f0f0",
        color: "#3f50b5",
    },
    icon: {
        minWidth: '30px',
    }
}));

export function MenuContentEditor(props) {
    const classes = useStyles();

    const {docIds, location, updateLocation, toggleMenu, isExpanded, moveArrowsDisabled} = props
    
    const {locationId, menuId} = docIds

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionsClose = () => {
        setAnchorEl(null);
    }

    const handleAddAction = () => {
        handleActionsClose()
        setDisplaySectionCreator(true)
    }

    const handleEditAction = () => {
        handleActionsClose()
        setDisplayMenuEditor(true)
    }

    const handleDeleteAction = () => {
        handleActionsClose()
        setDisplayMenuDeleteDialog(true)
    }

    const deleteMenu = () => {
        let newLocation = JSON.parse(JSON.stringify(location))

        deleteMenuChildImages(location.menus[menuId])

        //delete menu object
        delete newLocation.menus[menuId]

        // delete menu id
        const index = newLocation.menuList.indexOf(menuId);
        if (index > -1) {
            newLocation.menuList.splice(index, 1);
        }

        updateLocation(newLocation, ()=>{}, locationId)
    }

    const hideMenu = (visibility) => {
        location.menus[menuId].visibility = visibility
        updateLocation(location, ()=>{}, locationId)
    }

    const unhideMenu = () => {
        location.menus[menuId].visibility = {
            "hidden": false,
            "hiddenUntil": ""
        }
        updateLocation(location, ()=>{}, locationId)
    }

    const toggleMenuVisibility = () => {
        location.menus[menuId].visibility.hidden?
            unhideMenu()
            :
            setDisplayMenuHideDialog(true)
    }

    const moveMenu = (shiftBy) => {
        let newLocation = JSON.parse(JSON.stringify(location))

        MoveArray(newLocation.menuList, menuId, shiftBy)

        updateLocation(newLocation, ()=>{}, locationId)
    }

    const [displayMenuEditor, setDisplayMenuEditor] = React.useState(false)
    const [displaySectionCreator, setDisplaySectionCreator] = React.useState(false)
    const [displayMenuDeleteDialog, setDisplayMenuDeleteDialog] = React.useState(false)
    const [displayMenuHideDialog, setDisplayMenuHideDialog] = React.useState(false)

    return (
        <Grid container>
            <Grid container item alignItems="center" spacing={2}>
                <Grid item>
                    <Chip label="m" size="small" color="primary" variant="outlined"/>
                </Grid>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="body2">
                        {location.menus[menuId].basicInfo.name}
                    </Typography>
                </Grid>
                <Grid item>
                    {isExpanded(menuId) ?
                        <ExpandLess button onClick={() => toggleMenu(menuId)} className={classes.button}/>
                        :
                        <ExpandMore button onClick={() => toggleMenu(menuId)} className={classes.button}/>
                    }
                </Grid>
            </Grid>
            <Grid container item spacing={3} alignItems="center" justify="center">
                <Grid item>
                    <Tooltip title="Move Up" arrow interactive>
                        <IconButton size="small" color="primary" onClick={() => moveMenu(-1)} disabled={moveArrowsDisabled.up}>
                            <ArrowUpwardIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Move Down" arrow interactive>
                        <IconButton size="small" color="primary" onClick={() => moveMenu(+1)} disabled={moveArrowsDisabled.down}>
                            <ArrowDownwardIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {location.menus[menuId].visibility.hidden?
                        <Tooltip title="Unhide" arrow interactive>
                            <IconButton size="large" color="secondary" onClick={toggleMenuVisibility}>
                                <VisibilityOffIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Hide" arrow interactive>
                            <IconButton size="large" color="primary" onClick={toggleMenuVisibility}>
                                <VisibilityIcon/>
                            </IconButton>
                        </Tooltip>}
                    <HideDialog
                        itemType="Menu"
                        hideItem={hideMenu}
                        itemName={location.menus[menuId].basicInfo.name}
                        openDialog={displayMenuHideDialog}
                        handleDialogClose={() => setDisplayMenuHideDialog(false)}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        color="primary"
                        onClick={handleActionsClick}
                        size="medium"
                    >
                        <ListIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleActionsClose}
                    >
                        <MenuItem color="primary" onClick={handleAddAction}>
                            <ListItemIcon className={classes.icon}>
                                <AddIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" > Add Section</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleEditAction}>
                            <ListItemIcon className={classes.icon}>
                                <EditIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" > Edit Menu</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleDeleteAction}>
                            <ListItemIcon className={classes.icon}>
                                <DeleteIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" > Delete Menu</Typography>}/>
                        </MenuItem>
                    </Menu>
                    <DocumentEditor
                        openEditor={displaySectionCreator}
                        handleEditorClose={() => setDisplaySectionCreator(false)}
                        location={location}
                        updateLocation={updateLocation}
                        docIds={{
                            ...docIds,
                            sectionId: uniqid()
                        }}
                        section={GetSectionInitialValues()}
                        type="Section"
                        editMode={false}
                    />
                    <DocumentEditor
                        openEditor={displayMenuEditor}
                        handleEditorClose={() => setDisplayMenuEditor(false)}
                        location={JSON.parse(JSON.stringify(location))}
                        updateLocation={updateLocation}
                        menu={location.menus[menuId]}
                        docIds={docIds}
                        type="Menu"
                        editMode={true}
                    />
                    <DeleteDialog
                        itemType="Menu"
                        deleteItem={deleteMenu}
                        itemName={location.menus[menuId].basicInfo.name}
                        openDialog={displayMenuDeleteDialog}
                        handleDialogClose={() => setDisplayMenuDeleteDialog(false)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
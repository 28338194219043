import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage"
import "firebase/analytics";

var firebaseConfig = {
    apiKey: "AIzaSyAiH-Y81wS4raosmNYQ8aJiHiUST-C5mo4",
    authDomain: "menuapp-b2ac2.firebaseapp.com",
    databaseURL: "https://menuapp-b2ac2.firebaseio.com",
    projectId: "menuapp-b2ac2",
    storageBucket: "menuapp-b2ac2.appspot.com",
    messagingSenderId: "374763418",
    appId: "1:374763418:web:91e6ab1d0e98419ce69423",
    measurementId: "G-LX147QDXMH"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const firestorage = firebase.storage();
const analytics = firebase.analytics();

if (process.env.REACT_APP_STAGE !== 'prod') {
    // use emulator
    firestore.settings({ host: 'localhost:8000',  ssl: false });
}

export {firebase, firestore, firestorage, analytics};
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

export default function PublishDialog(props) {
    const {locationName, publishLocation, openDialog, handleDialogClose} = props

    const handlePublish = () => {
        publishLocation()
        handleDialogClose()
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>Publish Location</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container direction="column">
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                Are you sure you want to publish location {locationName}?
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                All of your draft changes will take effect immediately.
                            </DialogContentText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="space-evenly">
                        <Grid item>
                            <Button onClick={handlePublish} color="primary" size="large">
                                Publish
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleDialogClose} color="primary" size="large">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import {makeStyles, MenuItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
    dropDown: {
        width: "100%",
        minWidth: "200px"
    },
}));


export default function MoveDialog(props) {
    const classes = useStyles()

    const {location, updateLocation, docIds, entityType, openDialog, handleDialogClose} = props
    
    const {locationId, menuId, sectionId, itemId} = docIds

    const moveSection = (oldMenuId, newMenuId) => {
        // do nothing if no new menu was selection
        if (oldMenuId === newMenuId) return

        let updatedLocation = JSON.parse(JSON.stringify(location))

        let oldMenu = updatedLocation.menus[oldMenuId]
        let newMenu = updatedLocation.menus[newMenuId]

        // deep copy section object from old menu
        let sectionObj = JSON.parse(JSON.stringify(oldMenu.sections[sectionId]))

        // add section object to new menu
        newMenu.sections[sectionId] = sectionObj

        // add section id new menu
        newMenu.sectionList.push(sectionId)

        //delete section object from old menu
        delete oldMenu.sections[sectionId]

        // delete section id from old menu
        oldMenu.sectionList = oldMenu.sectionList.filter(value => value !== sectionId)

        // update location with the new changes in the db
        updateLocation(updatedLocation, ()=> handleDialogClose(), locationId)
    }

    const moveItem = (oldMenuId, newMenuId, oldSectionId, newSectionId) => {
        // do nothing if no new menu or no new section were selected
        if (oldMenuId === newMenuId && oldSectionId === newSectionId) return
        
        let updatedLocation = JSON.parse(JSON.stringify(location))

        let oldSection = updatedLocation.menus[oldMenuId].sections[oldSectionId]
        let newSection = updatedLocation.menus[newMenuId].sections[newSectionId]

        // deep copy item object from old menu,section
        let itemObj = JSON.parse(JSON.stringify(oldSection.items[itemId]))

        // add item object to new menu,section
        newSection.items[itemId] = itemObj

        // add section id new menu
        newSection.itemList.push(itemId)

        //delete item object from old menu,section
        delete oldSection.items[itemId]

        // delete item id from old menu,section
        oldSection.itemList = oldSection.itemList.filter(value => value !== itemId)

        // update location with the new changes in the db
        updateLocation(updatedLocation, ()=> handleDialogClose(), locationId)
    }

    const getDialogText = () => {
        let sourceMenu = location.menus[menuId].basicInfo.name
        let sourceSection = location.menus[menuId].sections[sectionId].basicInfo.name

        switch (entityType) {
            case "Section":
                return (
                    <Grid container direction="column">
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                {`${sourceSection} is currently under ${sourceMenu}.`}
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                You can move it to a different menu from the list below:
                            </DialogContentText>
                        </Grid>
                    </Grid>
                )

            case "Item":
                let sourceItem = location.menus[menuId].sections[sectionId].items[itemId].basicInfo.name
                return (
                    <Grid container direction="column">
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                {`${sourceItem} is currently under ${sourceSection} and ${sourceMenu}.`}
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <DialogContentText variant="subtitle2">
                                You can move it to a different section and/or menu from the list below:
                            </DialogContentText>
                        </Grid>
                    </Grid>
                )
            default:
                console.error("unexpected entity type", entityType)
        }
    }

    const onSubmit = data => {
        switch (entityType) {
            case "Section":
                moveSection(menuId, data.newMenuId)
                break
            case "Item":
                moveItem(menuId, data.newMenuId, sectionId, data.newSectionId)
                break
            default:
                console.error("unexpected entity type", entityType)
        }

        handleDialogClose()
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <Formik
                    initialValues={{
                        newMenuId: menuId,
                        newSectionId: sectionId
                    }}
                    onSubmit={onSubmit}
                >
                    {formProps => {
                        return (
                            <Form>
                                <DialogTitle>{`Move ${entityType}`}</DialogTitle>
                                <Divider style={{marginBottom:10}}/>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        {getDialogText()}
                                        <Grid item container spacing={4} alignItems="center">
                                            <Grid item>
                                                <Field
                                                    className={classes.dropDown}
                                                    label="Choose a menu"
                                                    component={TextField}
                                                    type="text"
                                                    select={true}
                                                    name="newMenuId"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => {
                                                        let newMenuIdValue = event.target.value
                                                        let newSectionIdValue = location.menus[newMenuIdValue].sectionList[0]

                                                        formProps.setFieldValue("newMenuId", newMenuIdValue)
                                                        formProps.setFieldValue("newSectionId", newSectionIdValue)
                                                    }}
                                                >
                                                    {location.menuList.map(
                                                        menuItemId =>
                                                            <MenuItem value={menuItemId}>
                                                                {location.menus[menuItemId].basicInfo.name}
                                                            </MenuItem>
                                                    )}
                                                </Field>
                                            </Grid>
                                            {
                                                entityType === "Item" &&
                                                <Grid item>
                                                    <Field
                                                        className={classes.dropDown}
                                                        label="Choose a Section"
                                                        component={TextField}
                                                        type="text"
                                                        select={true}
                                                        name="newSectionId"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                        {location.menus[formProps.values.newMenuId].sectionList.map(
                                                            sectionItemId =>
                                                                <MenuItem value={sectionItemId}>
                                                                    {location.menus[formProps.values.newMenuId].sections[sectionItemId].basicInfo.name}
                                                                </MenuItem>
                                                        )}
                                                    </Field>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container justify="space-evenly">
                                        <Grid item>
                                            <Button color="primary" size="large" type="submit">
                                                Move
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleDialogClose} color="primary" size="large">
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
        </div>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {DaysOfWeek} from '../util/consts'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LanguageIcon from '@material-ui/icons/Language';
import { Box, Link } from '@material-ui/core';
import {
    Facebook,
    Instagram,
    Twitter,
    Whatsapp,
  } from "@trejgun/material-ui-icons-social-networks";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
}));

function getHours(locationBody) {
    if (!locationBody.availability) return;
    let hoursByDay = new Map();
    locationBody.availability.forEach(a => {
        a.days.forEach(day => {
            if (!hoursByDay.has(day)) {
                hoursByDay.set(day, []);
            }
            hoursByDay.get(day).push(a.fromTime + ' - ' + a.toTime);
        })
    });
    return Array.from(DaysOfWeek).map((day) => {
        if (!hoursByDay.has(day)) {
            return <></>;
        }
        let hourRangeGridItems = hoursByDay.get(day).map((range) => {
            return (
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {range}
                    </Typography>
                </Grid>
            )
        })
        return (
            <Grid container justify='center'>
                <Grid item xs={12}>  
                    <Typography variant="subtitle2">
                        {day}
                    </Typography>
                </Grid>
                {hourRangeGridItems}
            </Grid>
        );
    });
}

export default function EaterFooter(props) {

    const classes = useStyles();

    return (
        <Box className={classes.root} square bgcolor="text.primary" color="white">
            <Grid container justify="center" direction="column" xs={12}  md={12} lg={12} sm={12} xl={12} spacing={2}>
                {props.locationBody.address &&
                    <Grid item>
                        <Grid container direction='column'>
                            <Grid item>
                                <LocationOnIcon/>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">
                                    {props.locationBody.address.street}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">
                                    {props.locationBody.address.city}, {props.locationBody.address.state} {props.locationBody.address.zipCode}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {props.locationBody.phoneNumber &&
                    <Grid item>
                        <Grid container direction='column'>
                            <Grid item>
                                <PhoneIcon/>
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom variant="subtitle2">
                                    {props.locationBody.phoneNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {props.locationBody.website &&
                    <Grid item>
                        <Grid container direction='column'>
                            <Grid item>
                                <LanguageIcon/>
                            </Grid>
                            <Grid item>
                                <Link href={'//' + props.locationBody.website} color='primary'>
                                    <Typography gutterBottom variant="subtitle2">
                                        {props.locationBody.website}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {props.locationBody.email &&
                    <Grid item>
                        <Grid container direction='column'>
                            <Grid item>
                                <EmailIcon/>
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom variant="body2">
                                    {props.locationBody.email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item>
                    <Grid container direction='column'>
                        <Grid item>
                            <QueryBuilderIcon/>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                {getHours(props.locationBody)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' spacing={2} justify='center'>
                        <Grid item>
                            {props.locationBody.socialMedia?.twitter &&
                                <Link href={'//www.twitter.com/' + props.locationBody.socialMedia.twitter} color='secondary'>
                                    <Twitter fontSize='large'/>
                                </Link>
                            }
                        </Grid>
                        <Grid item>
                            {props.locationBody.socialMedia?.instagram &&
                                <Link href={'//www.instagram.com/' + props.locationBody.socialMedia.instagram} color='primary'>
                                    <Instagram fontSize='large'/>
                                </Link>
                            }
                        </Grid>
                        <Grid item>
                            {props.locationBody.socialMedia?.facebook &&
                                <Link href={'//www.facebook.com/' + props.locationBody.socialMedia.facebook} color='primary'>
                                    <Facebook fontSize='large'/>
                                </Link>
                            }
                        </Grid>
                        <Grid item>
                            {props.locationBody.socialMedia?.whatsapp &&
                                <Link href={'https://api.whatsapp.com/send?phone=' + props.locationBody.socialMedia?.whatsapp.replace(/-+/g, '')}
                                        color='primary'>
                                    <Whatsapp fontSize='large'/>
                                </Link>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        Made with <span role="img" aria-label="love">❤</span>️ by <Link href="https://instaspoon.com/" target="_blank" style={{color:"white"}}>Instaspoon</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

import React from "react"
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DocumentEditor from "./DocumentEditor";
import DeleteDialog from "./DeleteDialog";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import HideDialog from "./HideDialog";
import {GetItemInitialValues} from "./InitialValues";
import uniqid from "uniqid"
import { deleteSectionChildImages } from "../../util/image-utils";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoveArray from "../../util/array-manipulation";
import {ListItemIcon, ListItemText, MenuItem, Tooltip} from "@material-ui/core";
import MoveDialog from "./MoveDialog";
import ListIcon from "@material-ui/icons/List";
import Menu from "@material-ui/core/Menu";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PanToolIcon from '@material-ui/icons/PanTool';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        marginBottom: 20
    },
    sectionMargin: {
        marginLeft: 3
    },
    button: {
        cursor: 'pointer',
        transition: theme.transitions.create(['background-color', 'transform'], {
            duration: theme.transitions.duration.standard,
        }),
        '&:hover': {
            transform: 'scale(1.5)',
        },
    },
    green: {
        color: 'green',
    },
    darkSelection: {
        backgroundColor: "#ebebeb",
        color: "#3f50b5",
    },
    lightSelection:{
        backgroundColor: "#ededed",
        color: "#3f50b5",
    },
    lightestSelection:{
        backgroundColor: "#f2f0f0",
        color: "#3f50b5",
    },
    icon: {
        minWidth: '30px',
    }
}));

export function SectionContentEditor(props) {
    const classes = useStyles();

    const {docIds, location, updateLocation, toggleSection, isExpanded, moveArrowsDisabled} = props

    const {locationId, menuId, sectionId} = docIds

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionsClose = () => {
        setAnchorEl(null);
    }

    const handleAddAction = () => {
        handleActionsClose()
        setDisplayItemCreator(true)
    }

    const handleEditAction = () => {
        handleActionsClose()
        setDisplaySectionEditor(true)
    }

    const handleDeleteAction = () => {
        handleActionsClose()
        setDisplaySectionDeleteDialog(true)
    }

    const handleMoveAction = () => {
        handleActionsClose()
        setDisplaySectionMoveDialog(true)
    }

    const deleteSection = () => {
        let newLocation = JSON.parse(JSON.stringify(location))

        deleteSectionChildImages(location.menus[menuId].sections[sectionId])

        //delete section object
        delete newLocation.menus[menuId].sections[sectionId]

        // delete section id
        const index = newLocation.menus[menuId].sectionList.indexOf(sectionId);
        if (index > -1) {
            newLocation.menus[menuId].sectionList.splice(index, 1);
        }

        updateLocation(newLocation, ()=>{}, locationId)
    }

    const hideSection = (visibility) => {
        location.menus[menuId].sections[sectionId].visibility = visibility
        updateLocation(location, ()=>{}, locationId)
    }

    const unhideSection = () => {
        location.menus[menuId].sections[sectionId].visibility = {
            "hidden": false,
            "hiddenUntil": ""
        }
        updateLocation(location, ()=>{}, locationId)
    }

    const toggleSectionVisibility = () => {
        location.menus[menuId].sections[sectionId].visibility.hidden?
            unhideSection()
            :
            setDisplaySectionHideDialog(true)
    }

    const moveSection = (shiftBy) => {
        let newLocation = JSON.parse(JSON.stringify(location))
        MoveArray(newLocation.menus[menuId].sectionList, sectionId, shiftBy)
        updateLocation(newLocation, ()=>{}, locationId)
    }

    const [displaySectionEditor, setDisplaySectionEditor] = React.useState(false)
    const [displayItemCreator, setDisplayItemCreator] = React.useState(false)
    const [displaySectionDeleteDialog, setDisplaySectionDeleteDialog] = React.useState(false)
    const [displaySectionHideDialog, setDisplaySectionHideDialog] = React.useState(false)
    const [displaySectionMoveDialog, setDisplaySectionMoveDialog] = React.useState(false)

    return (
        <Grid container className={classes.sectionMargin}>
            <Grid container item alignItems="center" spacing={2}>
                <Grid item>
                    <Chip label="s" color="primary" variant="outlined" size="small"/>
                </Grid>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="body2">
                        {location.menus[menuId].sections[sectionId].basicInfo.name}
                    </Typography>
                </Grid>
                <Grid item>
                    {isExpanded(sectionId) ?
                        <ExpandLess button onClick={() => toggleSection(sectionId)} className={classes.button}/>
                        :
                        <ExpandMore button onClick={() => toggleSection(sectionId)} className={classes.button}/>
                    }
                </Grid>
            </Grid>
            <Grid container item spacing={3} alignItems="center" justify="center">
                <Grid item>
                    <Tooltip title="Move Up" arrow interactive>
                        <IconButton size="small" color="primary" onClick={() => moveSection(-1)} disabled={moveArrowsDisabled.up}>
                            <ArrowUpwardIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Move Down" arrow interactive>
                        <IconButton size="small" color="primary" onClick={() => moveSection(+1)} disabled={moveArrowsDisabled.down}>
                            <ArrowDownwardIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {location.menus[menuId].sections[sectionId].visibility.hidden?
                        <Tooltip title="Unhide" arrow interactive>
                            <IconButton size="large" color="secondary" onClick={toggleSectionVisibility}>
                                <VisibilityOffIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Hide" arrow interactive>
                            <IconButton size="large" color="primary" onClick={toggleSectionVisibility}>
                                <VisibilityIcon/>
                            </IconButton>
                        </Tooltip>}
                    <HideDialog
                        itemType="Section"
                        hideItem={hideSection}
                        itemName={location.menus[menuId].sections[sectionId].basicInfo.name}
                        openDialog={displaySectionHideDialog}
                        handleDialogClose={() => setDisplaySectionHideDialog(false)}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        color="primary"
                        onClick={handleActionsClick}
                        size="medium"
                    >
                        <ListIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleActionsClose}
                    >
                        <MenuItem color="primary" onClick={handleAddAction}>
                            <ListItemIcon className={classes.icon}>
                                <AddIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Add Item</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleEditAction}>
                            <ListItemIcon className={classes.icon}>
                                <EditIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Edit Section</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleMoveAction}>
                            <ListItemIcon className={classes.icon}>
                                <PanToolIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Move Section</Typography>}/>
                        </MenuItem>
                        <MenuItem color="primary" onClick={handleDeleteAction}>
                            <ListItemIcon className={classes.icon}>
                                <DeleteIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText disableTypography primary={<Typography variant="body2" >Delete Section</Typography>}/>
                        </MenuItem>
                    </Menu>
                    <DocumentEditor
                        openEditor={displayItemCreator}
                        handleEditorClose={() => setDisplayItemCreator(false)}
                        location={JSON.parse(JSON.stringify(location))}
                        updateLocation={updateLocation}
                        item={GetItemInitialValues()}
                        docIds={{
                            ...docIds,
                            itemId: uniqid()
                        }}
                        type="Item"
                        editMode={false}
                    />
                    <DocumentEditor
                        openEditor={displaySectionEditor}
                        handleEditorClose={() => setDisplaySectionEditor(false)}
                        location={location}
                        updateLocation={updateLocation}
                        docIds={docIds}
                        section={location.menus[menuId].sections[sectionId]}
                        type="Section"
                        editMode={true}
                    />
                    <DeleteDialog
                        itemType="Section"
                        deleteItem={deleteSection}
                        itemName={location.menus[menuId].sections[sectionId].basicInfo.name}
                        openDialog={displaySectionDeleteDialog}
                        handleDialogClose={() => setDisplaySectionDeleteDialog(false)}
                    />
                    <MoveDialog
                        location={location}
                        updateLocation={updateLocation}
                        docIds={docIds}
                        entityType="Section"
                        openDialog={displaySectionMoveDialog}
                        handleDialogClose={() => setDisplaySectionMoveDialog(false)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
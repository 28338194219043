// toMatchableLocation converts a location document into a matchable location document
// which is a clone of the original location document minus items that do not match the search criteria
export function toMatchableLocation(location, itemSearchParam, tagsSearchParams) {
    // clone location to a matchable location
    let matchableLocation = JSON.parse(JSON.stringify(location))

    // if no item or tag search criteria were passed or if no menus found, return the entire document intact
    if ((!itemSearchParam && (!tagsSearchParams || tagsSearchParams.length === 0)) || !location["menus"]) {
        return matchableLocation
    }

    let matchableItemSearchParam = itemSearchParam?.toLowerCase();

    let menus = location["menus"]
    for (let menuId in menus) {
        if (!menus[menuId]["sections"]) {
            continue
        }
        let sections = menus[menuId]["sections"]
        for (let sectionId in sections) {
            if (!sections[sectionId]["items"]) {
                continue
            }
            let items = sections[sectionId]["items"]
            for (let itemId in items) {
                let item = items[itemId]

                // item matches search criteria if:
                // 1) item search param is a substring of name or description OR
                // 2) item search param is contained in item's ingredients OR
                // 3) item search param is contained in item's tags OR
                // 3) tags search params is not a sub-array of item's tags

                let nameFound = false
                let descFound = false
                let ingredientsFound = false
                let tagsFound = false

                let matchOnName = false
                let matchOnDesc = false
                let matchOnIngredients = false
                let matchOnTags = false
                let matchOnExplicitTags = false

                if (item["basicInfo"] && item["basicInfo"]["name"]) {
                    nameFound = true
                    let name = item["basicInfo"]["name"].toLowerCase()
                    if (matchableItemSearchParam) {
                        matchOnName = name.includes(matchableItemSearchParam)
                    }
                }
                if (item["basicInfo"] && item["basicInfo"]["description"]) {
                    descFound = true
                    let description = item["basicInfo"]["description"].toLowerCase()
                    if (matchableItemSearchParam) {
                        matchOnDesc = description.includes(matchableItemSearchParam)
                    }
                }
                if (item["ingredients"]) {
                    ingredientsFound = true
                    let ingredients = item["ingredients"].map(ingredient => ingredient.toLowerCase())
                    if (matchableItemSearchParam) {
                        matchOnIngredients = ingredients.includes(matchableItemSearchParam)
                    }
                }
                // tags is a special case; do both item searching and tag searching
                if (item["tags"]) {
                    tagsFound = true    
                    let tags = item["tags"].map(tag => tag.toLowerCase())
                    if (tagsSearchParams && tagsSearchParams.length !== 0) {
                        let tagsCount = tagsSearchParams.length
                        matchOnExplicitTags = tags.filter(value => tagsSearchParams.includes(value)).length >= tagsCount
                    }
                    if (matchableItemSearchParam) {
                        matchOnTags = tags.includes(matchableItemSearchParam)
                    }
                }

                let textMatch = (!matchableItemSearchParam || 
                                (nameFound && matchOnName) ||
                                (descFound && matchOnDesc) ||
                                (ingredientsFound && matchOnIngredients) ||
                                (tagsFound && matchOnTags));
                let tagMatch = (!tagsSearchParams || tagsSearchParams.length === 0) ||
                                (tagsFound && matchOnExplicitTags);
                if (textMatch && tagMatch) {
                    continue;
                }

                // delete item from matchable location document if it doesn't satisfy search criteria
                delete matchableLocation["menus"][menuId]["sections"][sectionId]["items"][itemId]
            }
            // delete secrion if no items are left
            if (Object.entries(matchableLocation["menus"][menuId]["sections"][sectionId]["items"]).length === 0) {
                delete matchableLocation["menus"][menuId]["sections"][sectionId]
            }
        }
    }

    return matchableLocation
}

export function getAllTags(location) {
    let tags = new Set();
    if (location["menus"]) { 
        Object.values(location["menus"]).forEach(
            menu => {
                if (menu["sections"]) {
                    Object.values(menu["sections"]).forEach(
                        section => {
                            if (section["items"]) {
                                Object.values(section["items"]).forEach(
                                    item => (
                                        item["tags"]?.forEach(
                                            tag => {
                                                tags.add(tag.toLowerCase());
                                            }
                                        )
                                    )
                                )
                            }
                        }
                    )
                }
            }
        )
    }
    return tags;
}

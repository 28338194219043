import React from "react"

import {Backdrop, CircularProgress, Divider, FormControlLabel, Radio, RadioGroup} from "@material-ui/core"
import {Dialog, DialogContent} from "@material-ui/core";
import {Button, Grid, Typography} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles";

import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";

import DialogTitle from "./../DialogTitle";
import {MenuValidationSchema} from "../../util/validation"
import FormControl from "@material-ui/core/FormControl";
import {GetNewAvailabilityInitialValues} from "./InitialValues";
import Availability from "./Availability";
import { deleteDraftImage, uploadDraftImage } from "../../util/image-utils";
import { mean } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formElement: {
        marginBottom: 20
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default function MenuEditor(props) {
    const classes = useStyles();

    const {userId, locationId, menuId} = props.docIds

    const [displayProgressBar, setDisplayProgressBar] = React.useState(false)

    const updateState = () => {
        setDisplayProgressBar(false)
        props.handleMenuEditorClose()
    }

    const saveMenuStateful = (menu, image) => {
        setDisplayProgressBar(true)
        props.setMenu(menu, image, updateState)
    }

    const onSubmit = formData => {
        let menu = {...formData.base}
        // Reset menu availability to default is availability is not custom
        menu.availability = menu.availabilityType!=="custom"? []: menu.availability

        let image = formData.image.data
        let imagePath = formData.image.path

        switch (image.status) {
            case "UNTOUCHED":
                saveMenuStateful(menu, {
                    url: image.url,
                    path: imagePath
                });
                break;

            case "UPDATED":
                uploadDraftImage(userId, locationId, 'menus', image.file, menuId, menu, saveMenuStateful);
                break;

            case "DELETED":
                deleteDraftImage(imagePath, mean, saveMenuStateful);
                break;

            default:
                console.error("unexpected image status ", image.status);
        }

    }

    return(
        <div>
            <Dialog
                open={props.openMenuEditor}
                onClose={props.handleMenuEditorClose}
                fullWidth={true}
                maxWidth="md"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle onClose={props.handleMenuEditorClose}>
                    Menu Editor
                </DialogTitle>
                <DialogContent dividers>
                    <Backdrop className={classes.backdrop} open={displayProgressBar}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Formik
                        initialValues={{
                            base: props.menu,
                            image: {
                                path: props.menu.basicInfo.image.path,
                                data: {
                                    status: "UNTOUCHED",
                                    url: props.menu.basicInfo.image.url,
                                    file: null,
                                }
                            },
                        }}
                        onSubmit={onSubmit}
                        validationSchema={MenuValidationSchema}
                    >
                        {formProps => {
                            return (
                                <Form className={classes.form}>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Name"
                                        name="base.basicInfo.name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        className={classes.formElement}
                                    />
                                    <Field
                                        component={TextField}
                                        multiline
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Description"
                                        name="base.basicInfo.description"
                                        rows={4}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Divider className={classes.formElement}/>
                                    <Grid container spacing={2} className={classes.formElement}>
                                        <Grid container item>
                                            <Grid item>
                                                <Typography variant="h6" color="primary">
                                                    Availability
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        value={formProps.values.base.availabilityType}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom" && formProps.values.base.availability.length === 0) {
                                                                formProps.setFieldValue("base.availability", [GetNewAvailabilityInitialValues()])
                                                            }
                                                            if (e.target.value !== "custom") {
                                                                formProps.setFieldValue("base.availability", [])
                                                            }
                                                            formProps.setFieldValue("base.availabilityType", e.target.value)
                                                        }}>
                                                        <FormControlLabel value="always" control={<Radio color="primary"/>} label="Always" />
                                                        <FormControlLabel value="custom" control={<Radio color="primary"/>} label="Custom" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Availability
                                                values={formProps.values.base.availability}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} className={classes.formElement} xs={12}>
                                        <Grid item xs={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={props.handleMenuEditorClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import LocationEditor from "./LocationEditor";
import {ListItemIcon, ListItemText, MenuItem, Paper} from "@material-ui/core";
import DeleteDialog from "./DeleteDialog";
import HideDialog from "./HideDialog";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DocumentEditor from "./DocumentEditor";
import {GetMenuInitialValues} from "./InitialValues";
import uniqid from "uniqid"
import ListIcon from "@material-ui/icons/List";
import Menu from "@material-ui/core/Menu";

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';

import LocationAliasDialog from "./LocationAliasDialog";

const useStyles = makeStyles((theme) => ({
    img: {
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%'
    },
    paper: {
        padding: theme.spacing(2),
    },
    icon: {
        minWidth: '30px',
    }
}));

export default function LocationViewer(props) {
    const classes = useStyles();

    const {docIds, location, updateLocation, deleteLocation, updateLocationAlias} = props

    const hideLocation = (visibility) => {
        location.visibility = visibility
        updateLocation(location, ()=>{})
    }

    const editLocationAlias = (updatedLocationAlias, updateState) => {
        updateLocationAlias(updatedLocationAlias, updateState)
    }

    const unhideLocation = () => {
        location.visibility = {
            "hidden": false,
            "hiddenUntil": ""
        }
        updateLocation(location, ()=>{})
    }

    const toggleLocationVisibility = () => {
        location.visibility.hidden?
            unhideLocation()
            :
            setDisplayLocationHideDialog(true)
    }

    const [openLocationEditor, setLocationEditorOpen] = React.useState(false)
    const [displayMenuCreator, setDisplayMenuCreator] = React.useState(false)
    const [displayLocationDeleteDialog, setDisplayLocationDeleteDialog] = React.useState(false)
    const [displayLocationHideDialog, setDisplayLocationHideDialog] = React.useState(false)
    const [displayLocationAliasDialog, setDisplayLocationAliasDialog] = React.useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionsClose = () => {
        setAnchorEl(null);
    }

    const handleAddAction = () => {
        handleActionsClose()
        setDisplayMenuCreator(true)
    }

    const handleEditAction = () => {
        handleActionsClose()
        setLocationEditorOpen(true)
    }

    const handleDeleteAction = () => {
        handleActionsClose()
        setDisplayLocationDeleteDialog(true)
    }

    const handleLocationAliasAction = () => {
        handleActionsClose()
        setDisplayLocationAliasDialog(true)
    }

    return (
        <Paper className={classes.paper} elevation={3}>
            <Grid item container spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item container>
                        <Grid item xs={12}>
                            {
                                location.logo.url &&
                                <img className={classes.img} alt="complex" src={location.logo.url} />
                            }
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item>
                            <Grid item container spacing={4}>
                                <Grid item>
                                    <Typography gutterBottom variant="body1">
                                        {location.name}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {location.address.street}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {location.address.city}, {location.address.state} {location.address.zipCode}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {location.phoneNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleActionsClick}
                            startIcon={<ListIcon/>}
                        >
                            Manage
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleActionsClose}
                        >
                            <MenuItem color="primary" onClick={handleAddAction}>
                                <ListItemIcon className={classes.icon}>
                                    <AddIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText disableTypography primary={<Typography variant="body2" > Add Menu</Typography>}/>
                            </MenuItem>
                            <MenuItem color="primary" onClick={handleEditAction}>
                                <ListItemIcon className={classes.icon}>
                                    <EditIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText disableTypography primary={<Typography variant="body2" > Edit Location</Typography>}/>
                            </MenuItem>
                            <MenuItem color="primary" onClick={handleDeleteAction}>
                                <ListItemIcon className={classes.icon}>
                                    <DeleteIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText disableTypography primary={<Typography variant="body2" > Delete Location</Typography>}/>
                            </MenuItem>
                        </Menu>
                        <DocumentEditor
                            openEditor={displayMenuCreator}
                            handleEditorClose={() => setDisplayMenuCreator(false)}
                            location={location}
                            updateLocation={updateLocation}
                            docIds={{
                                ...docIds,
                                menuId: uniqid()
                            }}
                            menu={GetMenuInitialValues()}
                            type="Menu"
                            editMode={false}
                        />
                        <LocationEditor
                            docIds = {docIds}
                            location = {location}
                            editLocation = {updateLocation}
                            openLocationEditor = {openLocationEditor}
                            setLocationEditorOpen = {setLocationEditorOpen}
                        />
                        <DeleteDialog
                            itemType="Location"
                            deleteItem={deleteLocation}
                            itemName={location.name}
                            openDialog={displayLocationDeleteDialog}
                            handleDialogClose={() => setDisplayLocationDeleteDialog(false)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={toggleLocationVisibility}
                            startIcon={
                                location.visibility.hidden?
                                    <VisibilityOffIcon fontSize="large" color="secondary"/>
                                    :
                                    <VisibilityIcon fontSize="large" color="primary"/>
                            }
                        >
                            {location.visibility.hidden? "Unhide": "Hide"}
                        </Button>
                        <HideDialog
                            itemType="Location"
                            hideItem={hideLocation}
                            itemName={location.name}
                            openDialog={displayLocationHideDialog}
                            handleDialogClose={() => setDisplayLocationHideDialog(false)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={handleLocationAliasAction}
                            startIcon={<LinkIcon fontSize="large"/>}
                        >
                            Link
                        </Button>
                        <LocationAliasDialog
                            originalLocationAlias={docIds.locationAlias}
                            editLocationAlias={editLocationAlias}
                            openDialog={displayLocationAliasDialog}
                            handleDialogClose={() => setDisplayLocationAliasDialog(false)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
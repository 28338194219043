import {DaysOfWeek} from "../../util/consts";

export function GetAvailabilityInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            ...DaysOfWeek.reduce((initialValues, dayOfWeek) => {
                return {
                    ...initialValues,
                    [dayOfWeek]: {
                        "available": false,
                        "startTime": "",
                        "endTime": ""
                    }
                }
            }, {})
        }
    ))
}

export function GetLocationDocumentInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            "userId": "",
            "alias": "",
            "draft": null,
            "live": null
        }
    ))
}

export function GetLocationInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            "name": "",
            "address": {
                "street": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
            },
            "phoneNumber": "",
            "website": "",
            "email": "",
            "socialMedia": {
                "twitter": "",
                "instagram": "",
                "facebook": "",
                "whatsapp": "",
            },
            "logo": {
                "url": "",
                "path": ""
            },
            "availability": [GetNewAvailabilityInitialValues()],
            "visibility": {
                "hidden": false,
                "hiddenUntil": ""
            },
            "menus": {
            },
            "menuList": []
        }
    ))
}

export function GetMenuInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            "visibility": {
                "hidden": false,
                "hiddenUntil": ""
            },
            "basicInfo": {
                "name": "",
                "description": "",
                "image": {
                    "url": "",
                    "path": ""
                },
            },
            "availabilityType": "always",
            "availability": [],
            "sections": {},
            "sectionList": []
        }
    ))
}

export function GetSectionInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            "visibility": {
                "hidden": false,
                "hiddenUntil": ""
            },
            "basicInfo": {
                "name": "",
                "description": "",
                "image": {
                    "url": "",
                    "path": ""
                },
            },
            "items": {},
            "itemList": []
        }
    ))
}

export function GetItemInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            "visibility": {
                "hidden": false,
                "hiddenUntil": ""
            },
            "basicInfo": {
                "name": "",
                "description": "",
                "image": {
                    "url": "",
                    "path": ""
                },
            },
            "ingredients": [],
            "tags": [],
            basePrice: 0,
            additionalPrices: []
        }
    ))
}

export function GetNewAvailabilityInitialValues() {
    return JSON.parse(JSON.stringify(
        {
            "days": [],
            "fromTime": "",
            "toTime": ""
        }
    ))
}
import React, {useEffect} from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Fab} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function ScrollToTop() {
    const classes = useStyles();

    const [isVisible, setIsVisible] = React.useState(false)

    useEffect(() => {
        document.addEventListener("scroll", function(e) {
            toggleVisibility();
        });
    })

    const toggleVisibility = () => {
        if (window.pageYOffset > 0) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        isVisible &&
        <Fab
            color="grey"
            size="large"
            onClick={scrollToTop} className={classes.fab}
        >
            <ArrowForwardIosIcon
                style={{transform: 'rotate(-90deg)'}}
                fontSize="large"
            />
        </Fab>
    )
}

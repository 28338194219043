import {analytics, firestore} from "../../util/firebase-client-utils";
import {GetLocationDocumentInitialValues} from "./InitialValues";
import {deleteAllLocationImages, discardDraftImages, publishDraftImages} from "../../util/image-utils"
import { getDayAge } from "../../util/date-utils";
//import Validate from "../../util/document-validation";

export const GetLocation = (locations, locationId) => {
    if (!locations.has(locationId)) {
        return {}
    }

    return locations.get(locationId).draft?
        locations.get(locationId).draft
        :
        locations.get(locationId).live
}

export const HasLive = (locations, locationId) => {
    return locations.has(locationId) && locations.get(locationId).live && true
}

export const HasDraft = (locations, locationId) => {
    return locations.has(locationId) && locations.get(locationId).draft && true
}

export const CreateLocation = (location, locationId, state, updateState) => {
    let {userId} = state

    let locationDoc = {
        ...GetLocationDocumentInitialValues(),
        userId:     userId,
        alias:      locationId,     // A location's alias is the same as the location ID initially
        draft:      location,
        created:    new Date().toISOString()
    }

    let locationAliasDoc = {
        userId: userId,
        locationId: locationId
    }

    createLocationInDB(locationDoc, locationAliasDoc, locationId, state, updateState)
}

export const EditLocation = (location, state, updateState) => {
    let {locationId, locations} = state

    if (!locations.has(locationId)) {
        return
    }

    // disregard edits if they yield in the same live location
    if (JSON.stringify(locations.get(locationId).live) === JSON.stringify(location)) {
        DiscardDraft(state, updateState)
        return
    }

    let newLocationDoc = {
        ...locations.get(locationId),
        draft: location
    }

    editLocationInDB(newLocationDoc, state, updateState)
}

export const PublishLocation = (state, updateState) => {
    let {userId, locationId, locations} = state

    if (!locations.has(locationId)) {
        return
    }

    publishDraftImages(userId, locationId);

    let newLocationDoc = {
        ...locations.get(locationId),
        draft: null,
        live: locations.get(locationId).draft
    }

    editLocationInDB(newLocationDoc, state, updateState)
}

export const DiscardDraft = (state, updateState) => {
    let {userId, locationId, locations} = state

    if (!locations.has(locationId)) {
        return
    }

    discardDraftImages(userId, locationId);

    let newLocationDoc = {
        ...locations.get(locationId),
        draft: null
    }

    if (!locations.get(locationId).live) {
        DeleteLocation(state, updateState)
        return
    }

    editLocationInDB(newLocationDoc, state, updateState)
}

export const DeleteLocation = (state, updateState) => {
    let {userId, locationId} = state
    return deleteAllLocationImages(userId, locationId)
        .then(() => deleteLocationInDB(state, updateState));
}

export const LoadLocationsFromDB = (userId, updateState) => {

    const locationsRef = firestore.collection('locations')
    locationsRef.where('userId', '==', userId).get()
        .then(snapshot => {
            let locations = new Map()
            let locationId = ""

            snapshot.forEach(doc => {
                locationId = locationId? locationId : doc.id
                locations.set(doc.id, doc.data())
            })

            updateState.OnSuccess(locationId, locations)
        })
        .catch(error => {
            updateState.OnFailure("load", error)
        })
}

const createLocationInDB = (locationDoc, locationAliasDoc, locationId, state, updateState) => {
    let {locations} = state

    // Not currently possible; have to write firestore trigger function to do server-side validation.

    // let validationResult = Validate(location)
    // if (!validationResult.valid) {
    //     updateState.OnFailure("create", validationResult.errors)
    //     return
    // }

    const batch = firestore.batch()

    const locationsRef = firestore.collection('locations').doc(locationId)
    const locationAlias = firestore.collection('locationAliases').doc(locationDoc.alias)

    batch.set(locationsRef, locationDoc)
    batch.set(locationAlias, locationAliasDoc)


    batch.commit()
        .then(() => {
            analytics.logEvent('create_location', {
                location_id: locationId,
                success: true,
            });
            updateState.OnSuccess(locationId, new Map(locations.set(locationId, locationDoc)))
        })
        .catch(error => {
            updateState.OnFailure("create", error)
            analytics.logEvent('create_location', {
                location_id: locationId,
                success: false,
            });
        })
}

const editLocationInDB = (location, state, updateState) => {
    let {locationId, locations} = state

    // Not currently possible; have to write firestore trigger function to do server-side validation.

    // let validationResult = Validate(location)
    // if (!validationResult.valid) {
    //     updateState.OnFailure("create", validationResult.errors)
    //     return
    // }

    const locationsRef = firestore.collection('locations')

    locationsRef.doc(locationId).set(location)
        .then(() => {
            analytics.logEvent('update_location', {
                location_id: locationId,
                location_day_age: getDayAge(location.created),
                success: true,
            });
            updateState.OnSuccess(locationId, new Map(locations.set(locationId, location)))
        })
        .catch(error => {
            updateState.OnFailure("update", error)
            analytics.logEvent('update_location', {
                location_id: locationId,
                success: false,
            });
        })
}

const deleteLocationInDB = (state, updateState) => {
    let {locationId, locations} = state

    const batch = firestore.batch()

    let locationAlias = locations.get(locationId).alias

    const deletedLocationRef = firestore.collection('locations').doc(locationId)
    const deletedLocationAliasRef = firestore.collection('locationAliases').doc(locationAlias)

    batch.delete(deletedLocationRef)
    batch.delete(deletedLocationAliasRef)

    batch.commit()
        .then(() => {
            locations.delete(locationId)

            updateState.OnSuccess(readFirstLocationId(locations), new Map(locations))
        })
        .catch(error => {
            updateState.OnFailure("delete", error)
        })
}

const readFirstLocationId = (locations) => {
    return locations.size? locations.keys().next().value: ""
}

export const IsLocationAliasTaken = (newLocationAlias) => {
    const locationAliasRef = firestore.collection('locationAliases').doc(newLocationAlias)

    return locationAliasRef.get()
}

export const EditLocationAlias = (newLocationAlias, state, updateState) => {
    let {locationId, userId, locations} = state

    const batch = firestore.batch()

    let oldLocationAlias = locations.get(locationId).alias

    const newLocationAliasRef  = firestore.collection('locationAliases').doc(newLocationAlias)
    const oldLocationAliasRef  = firestore.collection('locationAliases').doc(oldLocationAlias)
    const updatedLocationRef = firestore.collection('locations').doc(locationId)

    let locationAliasDoc = {
        userId: userId,
        locationId: locationId
    }
    let locationDoc = {
        ...locations.get(locationId),
        alias: newLocationAlias
    }

    batch.delete(oldLocationAliasRef) // Delete existing location alias
    batch.set(newLocationAliasRef, locationAliasDoc) // Create a new location alias
    batch.set(updatedLocationRef, locationDoc) // Update location with the new location alias

    batch.commit()
        .then(() => {
            updateState.OnSuccess(locationId, new Map(locations.set(locationId, locationDoc)))
        })
        .catch(error => {
            updateState.OnFailure("update location alias failure", error)
        })
}
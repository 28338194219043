import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Button from "@material-ui/core/Button";
import PublishDialog from "./PublishDialog";
import LocationViewer from "./LocationViewer";
import {LocationContentEditor} from "./LocationContentEditor";
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CheckIcon from '@material-ui/icons/Check';
import Divider from "@material-ui/core/Divider";
import DiscardDialog from "./DiscardDialog";
import LiveTvIcon from '@material-ui/icons/LiveTv';
import {Link} from "react-router-dom";
import {
    Backdrop,
    CircularProgress,
    ClickAwayListener,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Tooltip
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/List";
import Menu from "@material-ui/core/Menu";
import ScrollToTop from "../ScrollToTop";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    draftStyle: {
        color: '#f50057',
        borderColor: '#f50057',
        fontWeight: 'bold'
    },
    icon: {
        minWidth: '30px',
    }
}));

export default function DocumentManager(props) {
    const classes = useStyles();

    let {
        docIds,
        location,

        updateLocation,
        deleteLocation,
        discardDraftLocation,
        publishLocation,

        updateLocationAlias,

        hasLive,
        hasDraft
    } = props

    const [displayProgressBar, setDisplayProgressBar]       = React.useState(false)
    const [displayPublishDialog, setDisplayPublishDialog]   = React.useState(false)
    const [displayDiscardDialog, setDisplayDiscardDialog]   = React.useState(false)

    const updateState = () => {
        setDisplayProgressBar(false)
    }

    const deleteLocationStateful = () => {
        setDisplayProgressBar(true)
        deleteLocation(updateState)
    }

    const publishLocationStateful = () => {
        setDisplayProgressBar(true)
        publishLocation(updateState)
    }

    const discardDraftStateful = () => {
        setDisplayProgressBar(true)
        discardDraftLocation(updateState)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionsClose = () => {
        setAnchorEl(null);
    }

    const handlePreviewAction = () => {
        handleActionsClose()
    }

    const handlePublishAction = () => {
        handleActionsClose()
        setDisplayPublishDialog(true)
    }

    const handleDiscardAction = () => {
        handleActionsClose()
        setDisplayDiscardDialog(true)
    }



    const [openDraftTooltip, setOpenDraftTooltip] = React.useState(false);
    const [openLiveTooltip, setOpenLiveTooltip] = React.useState(false);

    const handleDraftTooltipClose = () => {
        setOpenDraftTooltip(false);
    };

    const handleDraftTooltipOpen = () => {
        setOpenDraftTooltip(true);
    };

    const handleLiveTooltipClose = () => {
        setOpenLiveTooltip(false);
    };

    const handleLiveTooltipOpen = () => {
        setOpenLiveTooltip(true);
    };

    return (
        <div>
            <Backdrop className={classes.backdrop} open={displayProgressBar}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ScrollToTop/>
            <Grid container spacing={2}>
                <Grid container item spacing={1} alignItems="center">
                    <Grid item>
                        <Button
                            disabled={!hasLive}
                            color="primary"
                            startIcon={<LiveTvIcon/>}
                            style={{fontWeight:'bold', textDecoration:'underline'}}
                            component={Link}
                            to={`/${docIds.locationAlias}`}
                            target="_blank"
                        >
                            My Live Location
                        </Button>
                    </Grid>
                    <Grid item>
                        <ClickAwayListener onClickAway={handleLiveTooltipClose}>
                            <div>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleLiveTooltipClose}
                                    open={openLiveTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="This is what your live location looks like to the public. Any unpublished changes are excluded."
                                >
                                    <IconButton onClick={handleLiveTooltipOpen} color="primary">
                                        <InfoOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    </Grid>
                </Grid>
                <Grid container item spacing={4}>
                    {
                        hasDraft &&
                            <Grid container item spacing={1} alignItems="center">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={handleActionsClick}
                                        startIcon={<ListIcon/>}
                                    >
                                        Draft Actions
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleActionsClose}
                                    >
                                        <MenuItem color="primary" onClick={handlePreviewAction} target="_blank" to={`/${docIds.locationAlias}/draft`} component={Link}>
                                            <ListItemIcon className={classes.icon}>
                                                <VisibilityIcon fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={<Typography variant="body2" >Preview</Typography>}/>
                                        </MenuItem>
                                        <MenuItem color="primary" onClick={handlePublishAction}>
                                            <ListItemIcon className={classes.icon}>
                                                <PublishIcon fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={<Typography variant="body2" >Publish</Typography>}/>
                                        </MenuItem>
                                        <MenuItem color="primary" onClick={handleDiscardAction}>
                                            <ListItemIcon className={classes.icon}>
                                                <ClearAllIcon fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={<Typography variant="body2" >Discard</Typography>}/>
                                        </MenuItem>
                                    </Menu>

                                    <PublishDialog
                                        publishLocation={publishLocationStateful}
                                        locationName={location.name}
                                        openDialog={displayPublishDialog}
                                        handleDialogClose={() => setDisplayPublishDialog(false)}
                                    />
                                    <DiscardDialog
                                        locationName={location.name}
                                        openDialog={displayDiscardDialog}
                                        discardDraftLocation={discardDraftStateful}
                                        handleDialogClose={() => setDisplayDiscardDialog(false)}
                                    />
                                </Grid>
                                <Grid item>
                                    <ClickAwayListener onClickAway={handleDraftTooltipClose}>
                                        <div>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleDraftTooltipClose}
                                                open={openDraftTooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Your location has unpublished changes. Use the Draft Actions below to manage them."
                                            >
                                                <IconButton onClick={handleDraftTooltipOpen} color="primary">
                                                    <InfoOutlinedIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                </Grid>
                            </Grid>
                    }
                    {
                        !hasDraft && hasLive &&
                        <Grid container item spacing={1} alignItems="center">
                            <Grid item>
                                <CheckIcon style={{color: "green"}} fontSize="small"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    All of your changes are live!
                                </Typography>
                                <Divider/>
                            </Grid>
                        </Grid>
                    }
                    <Grid container item spacing={4}>
                        <Grid item container>
                            <Box flexGrow={1}>
                                <Grid item>
                                    <LocationViewer
                                        docIds={docIds}
                                        location={location}
                                        updateLocation={updateLocation}
                                        deleteLocation={deleteLocationStateful}
                                        updateLocationAlias={updateLocationAlias}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                        <LocationContentEditor
                            docIds={docIds}
                            location={location}
                            updateLocation={updateLocation}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

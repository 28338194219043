import React, {useEffect} from 'react';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    CreateLocation, DeleteLocation, DiscardDraft,
    EditLocation, EditLocationAlias,
    GetLocation,
    HasDraft,
    HasLive,
    LoadLocationsFromDB,
    PublishLocation
} from "./LocationManager";
import Chip from "@material-ui/core/Chip";
import {Backdrop, Button, CircularProgress, Paper} from "@material-ui/core";
import LocationCreator from "./LocationCreator";
import uniqid from "uniqid";
import {GetLocationInitialValues} from "./InitialValues";
import DocumentManager from "./DocumentManager";
import Grid from "@material-ui/core/Grid";
import StorefrontIcon from '@material-ui/icons/Storefront';
import AdminFooter from "./AdminFooter";

const drawerWidth = 240;

const adminFooterHeight = '100px'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            zIndex: theme.zIndex.drawer + 1,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        zIndex: 1,
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        backgroundColor: 'inherit',
    },
    center: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    unselectedColor: {
        backgroundColor: '#f5f6f7',
        "&:hover": {
            backgroundColor: "#9e9e9e",
        },
    },
    selectedColor: {
        backgroundColor: '#d6d6d6',
        "&:hover": {
            backgroundColor: "#c2c0c0",
        },
    },
    createStyle: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: "white"
        },
        textAlign: 'center',
    },
    liveStyle: {
        color: 'green',
        borderColor: 'green',
        fontWeight: 'bold'
    },
    draftStyle: {
        color: '#f50057',
        borderColor: '#f50057',
        fontWeight: 'bold'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    title: {
        flexGrow: 1,
    },
    color: {
        backgroundColor: "inherit",
    }
}));

export default function MainPage(props) {
    const {window, userId, handleDrawerToggle, mobileOpen, footerHeight} = props;
    const classes = useStyles();
    const theme = useTheme();

    const [locations, setLocations]         = React.useState(new Map())
    const [selectedLoc, setSelectedLoc]     = React.useState("")
    const [locationAlias, setLocationAlias] = React.useState("")

    const [displayLocCreator, setDisplayLocCreator] = React.useState(false)
    const [displayProgressBar, setDisplayProgressBar] = React.useState(true)

    const getUpdateState = (updateChildState) => {
        return {
            OnSuccess: (locationId, locations) => {
                setLocations(locations)
                setSelectedLoc(locationId)
                setLocationAlias(locations.get(locationId)? locations.get(locationId).alias : "")
                updateChildState()
            },
            OnFailure: (action, errors) => {
                alert(`Failed to ${action} location. Please contact customer support.`)
                updateChildState()
                console.log(`data access error; failed to ${action} location with errors ${errors}`)
            }
        }
    }

    const getState = () => {
        return {
            userId:     userId,
            locations:  locations,
            locationId: selectedLoc,
        }
    }

    useEffect(() => {
        let updateState = getUpdateState(() => setDisplayProgressBar(false))
        LoadLocationsFromDB(userId, updateState)
    }, [userId])

    const createLocation = (location, updateChildState, locationId) => {
        CreateLocation(location, locationId, getState(), getUpdateState(updateChildState))
    }

    const updateLocation = (editedLocation, updateChildState, locationId) => {
        EditLocation(editedLocation, getState(), getUpdateState(updateChildState))
    }

    const updateLocationAlias = (updatedLocationAlias, updateChildState) => {
        EditLocationAlias(updatedLocationAlias, getState(), getUpdateState(updateChildState))
    }

    const publishLocation = (updateChildState) => {
        PublishLocation(getState(), getUpdateState(updateChildState))
    }

    const discardDraftLocation = (updateChildState) => {
        DiscardDraft(getState(), getUpdateState(updateChildState))
    }

    const deleteLocation = (updateChildState) => {
        DeleteLocation(getState(), getUpdateState(updateChildState))
    }

    const enableLocationCreator = () => {
        setDisplayLocCreator(true)
    }

    const selectLocation = (locationId) => {
        setSelectedLoc(locationId)
        setLocationAlias(locations.get(locationId)? locations.get(locationId).alias : "")
    }

    const getLocationColor = (locationId) => {
        return locationId === selectedLoc? classes.selectedColor: classes.unselectedColor
    }

    const deepCopy = (location) => {
        return JSON.parse(JSON.stringify(location))
    }


    const drawer = (
        <div>
            <div className={classes.toolbar} style={{backgroundColor: '#d3dfeb', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                <Typography variant="h6" color="primary">
                    My Locations
                </Typography>
            </div>
            <Divider style={{marginBottom: '-8px'}}/>
            <List>
                {[...locations.keys()].map((locationId, index) => (

                    locations.has(locationId) &&
                    <ListItem
                        button
                        value={locationId}
                        onClick={() => {
                            mobileOpen && handleDrawerToggle()
                            selectLocation(locationId)
                        }}
                        className={getLocationColor(locationId)}
                    >
                        <ListItemText primary={GetLocation(locations, locationId).name} />
                        {
                            HasDraft(locations, locationId)?
                                <Chip size="small" label="Draft" variant="outlined" className={classes.draftStyle}/>
                                :
                                <Chip size="small" label="Live" variant="outlined" className={classes.liveStyle}/>
                        }
                    </ListItem>
                ))}
                <Paper elevation={3} variant="outlined">
                    <ListItem button onClick={() => {
                        mobileOpen && handleDrawerToggle()
                        enableLocationCreator()
                    }} alignItems="center" className={classes.createStyle}>
                        <ListItemText disableTypography primary="Create Location"/>
                    </ListItem>
                </Paper>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        style={{
                            paddingBottom: footerHeight,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >

                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >

                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {
                    (() => {
                        if (displayProgressBar) {
                            return (
                                <Backdrop className={classes.backdrop} open={true}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            )
                        } else if (!locations.size && !displayLocCreator) {
                            return (
                                <>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        style={{ minHeight: '100vh', backgroundColor: 'inherit'}}
                                    >

                                        <Grid item>
                                            <Button startIcon={<StorefrontIcon/>} variant="text" color="primary" style={{fontSize: "large"}} onClick={enableLocationCreator}>
                                                Create my first location!
                                            </Button>
                                        </Grid>

                                    </Grid>
                                    <AdminFooter footerHeight={adminFooterHeight}/>
                                </>
                            )
                        } else if (displayLocCreator) {
                            return (
                                <LocationCreator
                                    docIds={{
                                        userId: userId,
                                        locationId: uniqid()
                                    }}
                                    location = {GetLocationInitialValues()}
                                    saveLocation = {createLocation}
                                    setDisplayLocCreator = {setDisplayLocCreator}
                                />
                            )
                        } else if (locations.has(selectedLoc)) {
                            return (
                                <>
                                    <DocumentManager
                                        docIds={{
                                            userId:     userId,
                                            locationId: selectedLoc,
                                            locationAlias:   locationAlias,
                                        }}
                                        location={deepCopy(GetLocation(locations, selectedLoc))}
                                        updateLocation={updateLocation}
                                        updateLocationAlias={updateLocationAlias}
                                        deleteLocation={deleteLocation}
                                        discardDraftLocation={discardDraftLocation}
                                        publishLocation={publishLocation}
                                        hasLive={HasLive(locations, selectedLoc)}
                                        hasDraft={HasDraft(locations, selectedLoc)}
                                    />
                                    <AdminFooter footerHeight={adminFooterHeight}/>
                                </>
                            )
                        }
                    })()
                }
            </main>
        </div>
    );
}
import React from "react"

import {Grid, MenuItem, Tooltip, makeStyles, Checkbox, ListItemText, Input, InputLabel} from "@material-ui/core"

import {Field, FieldArray} from "formik";
import {Select, TextField} from "formik-material-ui";

import InputMask from "react-input-mask";

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from "@material-ui/core/IconButton";

import {DaysOfWeek} from "../../util/consts";
import {GetNewAvailabilityInitialValues} from "./InitialValues";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    textfieldStyle: {
        width: "100%",

    },
    itemMargin: {
        marginBottom: 30,
    }
}));


export default function Availability(props) {
    const classes = useStyles()

    const {values} = props

    return(
        <FieldArray
            name={`base.availability`}
            render={ arrayHelpers => (
                <div style={{width: "100%"}}>
                    {
                        values.map((slot, index) => {
                            return (
                                <Grid container xs={12} sm={12} className={classes.itemMargin}>
                                    <Grid item xs={9} sm={9}>
                                        <Grid container item spacing={1}>
                                            <Grid item className={classes.textfieldStyle}>
                                                <FormControl className={classes.textfieldStyle}>
                                                    <InputLabel shrink>Select Days That Apply</InputLabel>
                                                    <Field
                                                        className={classes.textfieldStyle}
                                                        label="Select All Days That Apply"
                                                        component={Select}
                                                        type="text"
                                                        select={true}
                                                        name={`base.availability.${index}.days`}
                                                        multiple
                                                        input={<Input />}
                                                        renderValue={(selected) => <Typography variant="subtitle2">{selected.join(', ')}</Typography>}
                                                    >
                                                        {DaysOfWeek.map(dayOfWeek =>
                                                            <MenuItem key={dayOfWeek} value={dayOfWeek}>
                                                                <Checkbox color="primary" checked={values[index].days.indexOf(dayOfWeek) > -1} />
                                                                <ListItemText primary={dayOfWeek} />
                                                            </MenuItem>
                                                        )}
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid container item spacing={2}>
                                                <Grid item xs={6} s={6}>
                                                    <Field
                                                        name={`base.availability.${index}.fromTime`}
                                                        render={({ field }) => (
                                                            <InputMask
                                                                {...field}
                                                                mask="00:00 am"
                                                                maskChar=" "
                                                                formatChars={{
                                                                    0: '[0-9]',
                                                                    a: '[AaPp]',
                                                                    m: '[Mm]'
                                                                }}
                                                            >
                                                                {() => <
                                                                    Field
                                                                    className={classes.textfieldStyle}
                                                                    component={TextField}
                                                                    placeholder="08:00 AM"
                                                                    name={`base.availability.${index}.fromTime`}
                                                                    label="Start Time"
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />}
                                                            </InputMask>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} s={6}>
                                                    <Field
                                                        name={`base.availability.${index}.toTime`}
                                                        render={({ field }) => (
                                                            <InputMask
                                                                {...field}
                                                                mask="00:00 am"
                                                                maskChar=" "
                                                                formatChars={{
                                                                    0: '[0-9]',
                                                                    a: '[AaPp]',
                                                                    m: '[Mm]'
                                                                }}
                                                            >
                                                                {() => <
                                                                    Field
                                                                    className={classes.textfieldStyle}
                                                                    component={TextField}
                                                                    placeholder="05:00 PM"
                                                                    name={`base.availability.${index}.toTime`}
                                                                    label="End Time"
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />}
                                                            </InputMask>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <Grid container item alignItems="flex-end" justify="flex-end" spacing={4} direction="column">
                                            <Grid item></Grid>
                                            {
                                                index === values.length-1 &&
                                                <Grid item>
                                                    <Tooltip title="Add Time Slot" interactive>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => arrayHelpers.push(GetNewAvailabilityInitialValues())}
                                                            size="small"
                                                        >
                                                            <AddIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>

                                            }
                                            {
                                                values.length > 1 &&
                                                <Grid item>
                                                    <Tooltip title="Delete Time Slot" interactive>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                            size="small"
                                                        >
                                                            <RemoveIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
            )}
        />
    )
}
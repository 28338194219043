import React from "react"

import {Backdrop, CircularProgress, Divider} from "@material-ui/core"
import {Button, Grid, Typography, MenuItem} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles";

import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import MuiTextField from '@material-ui/core/TextField';

import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';

import InputMask from 'react-input-mask';

import {LocationValidationSchema} from "../../util/validation"
import {USStates} from "../../util/consts";
import InputAdornment from "@material-ui/core/InputAdornment";
import ImageManager from "./ImageManager";
import Availability from "./Availability";
import { deleteDraftImage, uploadDraftImage } from "../../util/image-utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formElement: {
        marginBottom: 20
    },
    fullWidth: {
        marginBottom: 20,
        width: "100%",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default function LocationCreator(props) {
    const classes = useStyles();

    const {userId, locationId} = props.docIds
    const [displayProgressBar, setDisplayProgressBar] = React.useState(false)

    const updateState = () => {
        setDisplayProgressBar(false)
        props.setDisplayLocCreator(false)
    }

    const saveLocationStateful = (location) => {
        setDisplayProgressBar(true)
        props.saveLocation(location, updateState, locationId)
    }

    const setLocation = (location, image) => {
        let updatedLocation = {
            ...location,
            logo: image,
        }
        saveLocationStateful(updatedLocation)
    }

    const onSubmit = formData => {
        let location = formData.base
        let image = formData.image.data
        let imagePath = formData.image.path

        switch (image.status) {
            case "UNTOUCHED":
                setLocation(location, {
                    url: image.url,
                    path: imagePath
                })
                break

            case "UPDATED":
                uploadDraftImage(userId, locationId, 'locations', image.file, locationId, location, setLocation);
                break

            case "DELETED":
                deleteDraftImage(imagePath, location, setLocation);
                break

            default:
                console.error("unexpected image status ", image.status)
        }
    }

    const onCancelSubmit = () => {
        props.setDisplayLocCreator(false)
    }

    return(
        <div>
            <Backdrop className={classes.backdrop} open={displayProgressBar}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                initialValues={{
                    base: props.location,
                    image: {
                        path: props.location.logo.path,
                        data: {
                            status: "UNTOUCHED",
                            url: props.location.logo.url,
                            file: null,
                        }
                    },
                }}
                onSubmit={onSubmit}
                validationSchema={LocationValidationSchema}
            >
                {formProps => {
                    return (
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <Form className={classes.form}>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Name"
                                        name="base.name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        className={classes.formElement}
                                    />
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Street Address"
                                        name="base.address.street"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="City"
                                        name="base.address.city"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="base.address.state"
                                        select={true}
                                        fullwidth
                                        label="State"
                                        variant="outlined"
                                        placeholder="Choose a state"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.fullWidth}
                                    >
                                        {USStates.map(state => {
                                            return (
                                                <MenuItem value={state}>{state}</MenuItem>
                                            )
                                        })}
                                    </Field>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Zip Code"
                                        name="base.address.zipCode"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Field
                                        name="base.phoneNumber"
                                        render={({ field }) => (
                                            <InputMask
                                                {...field}
                                                mask="+1 (999) 999 - 9999"
                                                disabled={false}
                                                maskChar=" "
                                            >
                                                {() => <
                                                    MuiTextField
                                                    name="base.phoneNumber"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullwidth
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    className={classes.fullWidth}
                                                />}
                                            </InputMask>
                                        )}
                                    />
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Email"
                                        name="base.email"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Website"
                                        name="base.website"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <Divider className={classes.formElement}/>
                                    <grid item xs={12} style={{
                                        margin: 'auto',
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                    }}>
                                        <ImageManager
                                            imageURL={formProps.values.image.data.url}
                                            updateImage={(imgFile, imgURL) => {
                                                formProps.setFieldValue("image.data", {
                                                    status: "UPDATED",
                                                    url: imgURL,
                                                    file: imgFile
                                                })
                                            }}
                                            deleteImage={() => formProps.setFieldValue("image.data", {status: "DELETED"})}
                                        />
                                    </grid>
                                    <div>
                                        <Divider className={classes.formElement}/>
                                        <Typography variant="body1" color="primary">
                                            Your Social Media Presence!
                                        </Typography>
                                        <Grid container spacing={1} alignItems="center" className={classes.formElement}>
                                            <Grid item>
                                                <InstagramIcon color="primary"/>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    name="base.socialMedia.instagram"
                                                    placeholder="Instagram"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} alignItems="center"className={classes.formElement}>
                                            <Grid item>
                                                <FacebookIcon color="primary"/>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    name="base.socialMedia.facebook"
                                                    placeholder="Facebook"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">/</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} alignItems="center" className={classes.formElement}>
                                            <Grid item>
                                                <WhatsAppIcon color="primary"/>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    name="base.socialMedia.whatsapp"
                                                    placeholder="Whatsapp"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} alignItems="center" className={classes.formElement}>
                                            <Grid item>
                                                <TwitterIcon color="primary"/>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    name="base.socialMedia.twitter"
                                                    placeholder="Twitter"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Divider className={classes.formElement}/>
                                        <Typography variant="body1" color="primary" className={classes.formElement}>
                                            Hours Of Operation
                                        </Typography>
                                        <Grid container xs={12} className={classes.formElement}>
                                            <Availability
                                                values={formProps.values.base.availability}
                                            />
                                        </Grid>
                                        <Grid container spacing={2} className={classes.formElement} xs={12}>
                                            <Grid item xs={6}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onCancelSubmit}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Form>
                            </Grid>
                        </Grid>

                    )
                }}
            </Formik>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import { analytics, firestore } from '../util/firebase-client-utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Location from '../components/Location';
import { useParams } from 'react-router-dom';
import {AppBar, createMuiTheme} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { getDayAge } from '../util/date-utils';
import HiddenLocation from '../components/HiddenLocation';
import { isEntityVisible } from '../util/visibility';

const useStyles = makeStyles((theme) => ({
    progress: {
        position: "fixed",
        overflow: "show",
        margin: "auto",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "50px",
        height: "50px"
    },
    appbar: {
        alignItems: 'center',
        backgroundColor: 'black',
    }
}));

const EATER_THEME = createMuiTheme({
    typography: {
        "fontFamily": `'Roboto', sans-serif;`,
    }
});

function Home() {
    const classes = useStyles();
    const [location, setLocation] = useState();
    const [errorMsg, setErrorMsg] = useState();

    let { locationAlias, locationType } = useParams();
    let actualLocationType = (locationType)? locationType : 'live'

    useEffect(() => {
        async function fetchLocation() {
            const locationAliasRef = firestore.collection('locationAliases').doc(locationAlias);

            await firestore.runTransaction((transaction) => {
                return transaction.get(locationAliasRef).then((locationAliasDoc) => {
                    if (!locationAliasDoc.exists) {
                        throw new Error('location alias document does not exist!')
                    }

                    let locationId = locationAliasDoc.data().locationId
                    const locationRef = firestore.collection('locations').doc(locationId);

                    transaction.get(locationRef).then((locationDoc) => {
                        if (!locationDoc.exists) {
                            throw new Error('location document does not exist!')
                        }

                        let location = locationDoc.data()
                        let locationId = location.id

                        setLocation(location[actualLocationType]);

                        analytics.logEvent('view_location', {
                            location_id: locationId,
                            location_alias: locationAlias,
                            location_day_age: getDayAge(location.created),
                            success: true,
                        });
                    })
                })
            }).then(() => {
                //console.log('location successfully fetched from the database')
            }).catch((error) => {
                //console.log(error);
                setErrorMsg("Could not retrieve menu. Please try again.")
            })
        }

        fetchLocation();
    }, [locationAlias, actualLocationType]);

    if (errorMsg) {
        return <Alert severity="error">{errorMsg}</Alert>;
    }

    if (!location) {
        return <CircularProgress className={classes.progress} />;
    }

    return (
        <MuiThemeProvider theme={EATER_THEME}>
            <div>
                {
                    actualLocationType === "draft" &&
                    <AppBar position="sticky" className={classes.appbar}>
                        <Toolbar>
                            <Typography variant="h5">LOCATION DRAFT MODE</Typography>
                        </Toolbar>
                    </AppBar>
                }
                {
                    isEntityVisible(location, new Date()) ?
                        <Location locationBody={location} viewAll={true} /> :
                        <HiddenLocation />
                }

            </div>
        </MuiThemeProvider>
    );
}

export default Home;
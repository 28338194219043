import React from "react"

import {Backdrop, CircularProgress} from "@material-ui/core"
import {Dialog, DialogContent} from "@material-ui/core";
import {Button, Grid} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles";

import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";

import DialogTitle from "./../DialogTitle";
import {SectionValidationSchema} from "../../util/validation";
import ImageManager from "./ImageManager";
import { deleteDraftImage, uploadDraftImage } from "../../util/image-utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formElement: {
        marginBottom: 20
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default function SectionEditor(props) {
    const classes = useStyles();

    const {userId, locationId, sectionId} = props.docIds

    const [displayProgressBar, setDisplayProgressBar] = React.useState(false)

    const updateState = () => {
        setDisplayProgressBar(false)
        props.handleSectionEditorClose()
    }

    const saveSectionStateful = (section, image) => {
        setDisplayProgressBar(true)
        props.setSection(section, image, updateState)
    }

    const onSubmit = formData => {
        let section = formData.base

        let image = formData.image.data
        let imagePath = formData.image.path

        switch (image.status) {
            case "UNTOUCHED":
                saveSectionStateful(section, {
                    url: image.url,
                    path: imagePath
                });
                break;

            case "UPDATED":
                uploadDraftImage(userId, locationId, 'sections', image.file, sectionId, section, saveSectionStateful);
                break;

            case "DELETED":
                deleteDraftImage(imagePath, section, saveSectionStateful);
                break;

            default:
                console.error("unexpected image status ", image.status);
        }

        //props.handleSectionEditorClose()
    }
    return(
        <div>
            <Dialog
                open={props.openSectionEditor}
                onClose={props.handleSectionEditorClose}
                fullWidth={true}
                maxWidth="md"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle onClose={props.handleSectionEditorClose}>
                    Section Editor
                </DialogTitle>
                <DialogContent dividers>
                    <Backdrop className={classes.backdrop} open={displayProgressBar}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Formik
                        initialValues={{
                            base: props.section,
                            image: {
                                path: props.section.basicInfo.image.path,
                                data: {
                                    status: "UNTOUCHED",
                                    url: props.section.basicInfo.image.url,
                                    file: null,
                                }
                            },
                        }}
                        onSubmit={onSubmit}
                        validationSchema={SectionValidationSchema}
                    >
                        {formProps => {
                            return (
                                <Form className={classes.form}>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Name"
                                        name="base.basicInfo.name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        className={classes.formElement}
                                    />

                                    <Field
                                        component={TextField}
                                        multiline
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Description"
                                        name="base.basicInfo.description"
                                        rows={4}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.formElement}
                                    />
                                    <grid item xs={12} style={{
                                        margin: 'auto',
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        marginBottom: 20
                                    }}>
                                        <ImageManager
                                            imageURL={formProps.values.image.data.url}
                                            updateImage={(imgFile, imgURL) => {
                                                formProps.setFieldValue("image.data", {
                                                    status: "UPDATED",
                                                    url: imgURL,
                                                    file: imgFile
                                                })
                                            }}
                                            deleteImage={() => formProps.setFieldValue("image.data", {status: "DELETED"})}
                                        />
                                    </grid>
                                    <Grid container spacing={2} className={classes.formElement} xs={12}>
                                        <Grid item xs={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={props.handleSectionEditorClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles} from '@material-ui/core/styles';
import {Link as MuiLink} from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {Facebook, Instagram, Twitter} from "@trejgun/material-ui-icons-social-networks";

const useStyles = makeStyles((theme) => ({
    root: {
        //position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#282828',
        color: 'white',
        textAlign: 'center',
        zIndex: 2,
    },
}));

export default function AdminFooter1(props) {
    const { footerHeight } = props;
    const classes = useStyles();

    return (
        <div className={classes.root} style={{height: footerHeight}}>
            <Grid container justify="space-evenly">
                <Grid item>
                    <Grid container item direction="column" spacing={0} alignItems="flex-start" justify="space-around">
                        <Grid item>
                            <Button
                                component={Link}
                                to={'agreements/termsOfService'}
                                target="_blank"
                                color="inherit"
                                size="small"
                            >
                                Terms Of Service
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                component={Link}
                                to={'agreements/privacyPolicy'}
                                target="_blank"
                                color="inherit"
                                size="small"
                            >
                                Privacy Policy
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                component="a"
                                href="mailto:info@instaspoon.com"
                                color="inherit"
                                size="small"
                            >
                                Contact Us
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container item direction="column" spacing={1}>
                        <Grid item/>
                        <Grid item>
                            <Grid container spacing={3} justify='center'>
                                <Grid item>
                                    <MuiLink href={'https://www.facebook.com/Instaspoon-119721560298795'} target="_blank" color='primary'>
                                        <Facebook fontSize='small'/>
                                    </MuiLink>
                                </Grid>
                                <Grid item>
                                    <MuiLink href={'https://www.instagram.com/instaspoonofficial/'} target="_blank" color='primary'>
                                        <Instagram fontSize='small'/>
                                    </MuiLink>
                                </Grid>
                                <Grid item>
                                    <MuiLink href={'https://www.twitter.com/instaspoon'} target="_blank" color='secondary'>
                                        <Twitter fontSize='small'/>
                                    </MuiLink>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item></Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Made with <span role="img" aria-label="love">❤</span>️ by <MuiLink href="https://instaspoon.com/" target="_blank" style={{color:"white"}}>Instaspoon</MuiLink>
                            </Typography>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
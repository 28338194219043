import React from "react"
import MenuEditor from "./MenuEditor";
import SectionEditor from "./SectionEditor";
import ItemEditor from "./ItemEditor";

export default function DocumentEditor(props) {
    const {
        location,
        updateLocation,

        menu,
        section,
        item,

        editMode,
        docIds,
        type,

        openEditor,
        handleEditorClose
    } = props

    const {locationId, menuId, sectionId, itemId} = docIds

    const addMenuToLocation = (menu, image, updateState) => {
        !editMode && location["menuList"].push(menuId)

        location["menus"][menuId] =  menu
        location["menus"][menuId]["basicInfo"]["image"] = image
        updateLocation(location, updateState, locationId)
    }

    const addSectionToMenu = (section, image, updateState) => {
        !editMode && location["menus"][menuId]["sectionList"].push(sectionId)

        location["menus"][menuId]["sections"][sectionId] = section
        location["menus"][menuId]["sections"][sectionId]["basicInfo"]["image"] = image

        updateLocation(location, updateState, locationId)
    }

    const addItemToSection = (item, image, updateState) => {
        !editMode && location["menus"][menuId]["sections"][sectionId]["itemList"].push(itemId)

        location["menus"][menuId]["sections"][sectionId]["items"][itemId] = item
        location["menus"][menuId]["sections"][sectionId]["items"][itemId]["basicInfo"]["image"] = image
        updateLocation(location, updateState, locationId)
    }

    switch (type) {
        case "Menu":
            return <MenuEditor
                docIds={docIds}
                menu={menu}
                setMenu={addMenuToLocation}
                openMenuEditor={openEditor}
                handleMenuEditorClose={handleEditorClose}
            />
        case "Section":
            return <SectionEditor
                docIds={docIds}
                section={section}
                setSection={addSectionToMenu}
                openSectionEditor={openEditor}
                handleSectionEditorClose={handleEditorClose}
            />
        case "Item":
            return <ItemEditor
                docIds={docIds}
                item={item}
                setItem={addItemToSection}
                openItemEditor={openEditor}
                handleItemEditorClose={handleEditorClose}
            />
        default:
            console.error("unsupported document type: ", type)
    }
}
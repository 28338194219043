import * as Yup from "yup";
import moment from "moment"

const urlRegex = "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
const timeRegex= "((1[0-2]|0[1-9]):([0-5][0-9]) ?([AaPp][Mm]))"

export const DaysNumeric = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
}

const availabilityValidationSchema = {
    days: Yup.array().of(Yup.string()).min(1, "No days are selected"),
    fromTime: Yup.string().required("Required").trim().matches(timeRegex, "time format HH:MM AM/PM"),
    toTime: Yup.string().required("Required").trim().matches(timeRegex, "time format HH:MM AM/PM")
        .test(
            "timeValidation",
            "End time must be after start time",
            function (toTime) {
                const {fromTime} = this.parent
                return fromTime && toTime && isSameOrBefore(fromTime, toTime)
            }
        )
}

const isSameOrBefore = (startTime, endTime) => {
    let startTimeOfDay = startTime.split(" ")[1]
    let endTimeOfDay = endTime.split(" ")[1]

    if (startTimeOfDay === endTimeOfDay) {
        return moment(startTime, 'HH:mm a').isSameOrBefore(moment(endTime, 'HH:mm a'));
    }

    return true
}

export const MenuValidationSchema = Yup.object({
    base: Yup.object({
        basicInfo: Yup.object({
            name: Yup.string().required("Required"),
        }),

        availability: Yup.array().of(Yup.object({
            ...availabilityValidationSchema
        }))
    })
})

export const SectionValidationSchema = Yup.object({
    base: Yup.object({
        basicInfo: Yup.object({
            name: Yup.string().required("Required"),
        }),
        availability: Yup.array().of(Yup.object({
            ...availabilityValidationSchema
        }))
    })
})

export const ItemValidationSchema = Yup.object({
    base: Yup.object({
        basicInfo: Yup.object({
            name: Yup.string().required("Required"),
        }),
        basePrice: Yup.number().min(0, "price must be a positive number")
            .when("additionalPrices", {
                is: (additionalPrices) => additionalPrices.length===0,
                then: Yup.number().required("Please enter price, custom prices or both")
            }),
    }),
    additionalPrice: Yup.object({
        price: Yup.number().min(0, "price must be a positive number")
    })
})

export const LocationValidationSchema = Yup.object({
    base: Yup.object({
        name: Yup.string().required("Required"),
        address: Yup.object({
            street: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            state: Yup.string().required("Required"),
            zipCode: Yup.string().required("Required")
        }),
        website: Yup.string().matches(urlRegex, "Website should be a valid URL"),
        email: Yup.string().email("Please enter a valid email address"),
        socialMedia: Yup.object({
            twitter: Yup.string().matches("^[a-zA-Z0-9._]{1,500}$"),
            instagram: Yup.string().matches("^[a-zA-Z0-9._]{1,500}$"),
            facebook: Yup.string().matches("^[a-zA-Z0-9._]{1,500}$"),
            whatsapp: Yup.string().matches("^[0-9()-+]{1,50}")
        }),
        availability: Yup.array().of(Yup.object({
            ...availabilityValidationSchema
        }))
    })
})

export const HideValidationSchema = Yup.object().shape({
    hideUntil: Yup.boolean(),
    unhideDate: Yup.date("Enter a valid date").when("hideUntil", {is: true, then: Yup.date("Enter a valid date").required("Required")})
})
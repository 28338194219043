import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Section from './Section';
import ScrollSpyTabs from "./ScrollSpyTabs";
import { Collapse, Grid, Typography } from "@material-ui/core";
import { DaysOfWeek } from "../util/consts";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { isEntityVisible } from '../util/visibility';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 0,
    },
    menuDescription: {
        marginTop: 10,
        color: theme.palette.text.disabled,
    },
}));

export default function Menu(props) {

    const classes = useStyles();

    const sectionMap = new Map(Object.entries(props.menuBody.sections));
    const sectionArray = props.menuBody.sectionList;
    // remove hidden sections
    let now = new Date();
    sectionMap.forEach((section, sectionId) => {
        if (!isEntityVisible(section, now)) {
            sectionMap.delete(sectionId);
        }
    });

    const sections = sectionArray.filter(sectionId => sectionMap.get(sectionId) != null)
        .map((sectionId, index) => {
            const section = sectionMap.get(sectionId);
            return ({
                text: section.basicInfo.name,
                component: <Section key={sectionId} sectionId={sectionId} sectionBody={section} />
            });
        });

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={classes.root}>
            <Grid container item justify="center" direction="column" alignItems="center">
                {
                    props.menuBody.basicInfo.description &&
                    <Grid item className={classes.menuDescription}>
                        <Typography variant="subtitle2" align="center">
                            {props.menuBody.basicInfo.description}
                        </Typography>
                    </Grid>
                }
                {
                    props.menuBody.availabilityType === "custom" &&
                    <Grid item justify="center" alignItems="center" >
                        <Grid container item alignItems="center" justify="center" >
                            
                            <Grid item>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}
                                    onClick={handleExpandClick}
                                >
                                    {
                                        expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                    }
                                </IconButton>
                            </Grid>
                            <Grid item style={{ width: 140 }} >
                                <Typography variant="caption">
                                    View Menu Hours
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="center" justify="center">
                            <Grid item style={{ width: "100%" }}>
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    {getHours(props.menuBody)}
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Grid>

                }
            </Grid>
            <ScrollSpyTabs tabsInScroll={sections} />
        </div>
    );
}

function getHours(menuBody) {
    if (!menuBody.availability) return;
    let hoursByDay = new Map();
    menuBody.availability.forEach(a => {
        a.days.forEach(day => {
            if (!hoursByDay.has(day)) {
                hoursByDay.set(day, []);
            }
            hoursByDay.get(day).push('   ' + a.fromTime.replace(/\s/g, '') + ' - ' + a.toTime.replace(/\s/g, ''));
        })
    });
    return Array.from(DaysOfWeek).map((day) => {
        if (!hoursByDay.has(day)) {
            return <></>;
        }

        return hoursByDay.get(day).map((range, index) => {
            return (
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} justify='flex-end'>
                        {
                            index === 0 &&
                            <Typography variant="caption">
                                {day}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} justify='flex-start'>
                        <Typography variant="caption">
                            {range}
                        </Typography>
                    </Grid>
                </Grid>
            )
        })
    });
}
const MoveArray = (arr, element, newIndexShift) => {
    let fromIndex = arr.indexOf(element)
    let toIndex = fromIndex + newIndexShift

    if (!isValidIndex(arr, fromIndex) || !isValidIndex(arr, toIndex)) return

    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

const isValidIndex = (arr, index) => {
    return index >= 0 && index < arr.length
}

export default MoveArray;
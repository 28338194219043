/*

Utilities for handling image upload and cleanup.

Use cases:

(1) Adding a new image use case:
    - Upload an image to 
    users/<userId>/locations/<locationId>/items/<itemId>/<randImageName>
    - Create a marker in
    users/<userId>/locations/<locationId>/draft/items/<itemId>/<randImageName>.created

(2) Replacing an existing image
    - Upload new image
    - Create draft marker for it
    - Create a delete draft marker for old image
    users/<userId>/locations/<locationId>/draft/items/<itemId>/<randImageName>.deleted

(3) Discarding a draft
    - Delete all the draft markers
        If a draft marker is a “create” marker, delete the image it is pointing to.

(4) Publishing a draft
    - Delete all the draft markers
        If a draft marker is a “delete” marker, delete the image it is pointing to.

 */

import { firestorage } from "./firebase-client-utils";
import uniqid from "uniqid"
import imageCompression from 'browser-image-compression';

async function doUploadImage(imageFile, imagePath, item, setItem) {
    // Nothing to upload if image file is empty
    if (!imageFile) {
        return
    }

    let storageRef = firestorage.ref()

    const imageExtension  = imageFile.type.split("/")[1]
    const imageAddress    = `${imagePath}.${imageExtension}`

    return storageRef.child(imageAddress).put(imageFile)
        .then(uploadTask =>
            uploadTask.ref.getDownloadURL().then(function(imageURL) {
                console.log("image uploaded successfully")

                setItem(item, {
                    url: imageURL,
                    path: uploadTask.metadata.fullPath
                });
                let draftCreateMarker = getDraftMarkerName(uploadTask.metadata.fullPath, MarkerType.CREATE);
                uploadMarker(draftCreateMarker);
            })
        )
        .catch(error => {
            console.log("failed to upload menu image", error)
        });
}

async function uploadImageWithCompression(imageFile, imagePath, item, setItem) {
    // Nothing to upload if image file is empty
    if (!imageFile) {
        return
    }

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 345,
        useWebWorker: true,
        initialQuality: 1
    }

    try {
        const compressedFile = await imageCompression(imageFile, options);

        return doUploadImage(compressedFile, imagePath, item, setItem)
    } catch (error) {
        console.log("failed to compress image", error);
    }
}

async function doDeleteImage(imagePath, item, setItem) {
    // Nothing to delete if image path is empty
    if (!imagePath) {
        return
    }

    let storageRef = firestorage.ref()

    return storageRef.child(imagePath).delete()
        .then(function() {
            console.log("Image deleted successfully!")

            setItem(item, {
                url: "",
                path: ""
            })
        }).catch(function(error) {
        console.log("failed to delete menu image", error)
    })
}

export const MarkerType = {
    DELETE: 'deleted',
    CREATE: 'created'
};

export function getImagePrefix(userId, locationId) {
    return `users/${userId}/locations/${locationId}/`;
}

export function getDraftMarkerPrefix(userId, locationId) {
    return getImagePrefix(userId, locationId) + '/draft/';
}

export function getDraftMarkerName(imagePath, markerType) {
    let parts = imagePath.split('/');
    return `${parts[0]}/${parts[1]}/${parts[2]}/${parts[3]}/draft/${parts[4]}/${parts[5]}/${parts[6]}.${markerType}`;
}

export function getDraftMarkerDestination(markerPath, markerType) {
    return markerPath.replace('/draft/', '/').replace('.' + markerType, '');
}

export async function uploadMarker(markerPath) {
    let storageRef = firestorage.ref()
    return storageRef.child(markerPath).putString("")
        .then(uploadTask => {
            uploadTask.ref.getDownloadURL().then(function (imageURL) {
                console.log("marker created successfully at", markerPath, imageURL)
            })
        })
        .catch(error => {
            console.log("failed to create marker", error)
        })
}

export async function uploadDraftImage(userId, locationId, itemPrefix, imageFile, itemId, item, setItem) {
    let uploadMarkerTask = () => {};
    if (item.basicInfo?.image?.path) {
        // a previous image already exists, mark it for deletion
        let draftDeleteMarker = getDraftMarkerName(item.basicInfo.image.path, MarkerType.DELETE);
        uploadMarkerTask = () => uploadMarker(draftDeleteMarker);
    }

    const imagePath = getImagePrefix(userId, locationId) + `${itemPrefix}/${itemId}/${uniqid()}`;
    return uploadImageWithCompression(imageFile, imagePath, item, setItem).then(uploadMarkerTask);
}

export async function deleteDraftImage(imagePath, item, setItem) {
    let draftCreateMarker = getDraftMarkerName(imagePath, MarkerType.CREATE);
    let draftDeleteMarker = getDraftMarkerName(imagePath, MarkerType.DELETE);
    return ifExists(draftCreateMarker,
        () => {
            // image was created in draft and hasn't been published
            doDeleteImage(imagePath, item, setItem);
            doDeleteImage(draftCreateMarker, item, () => {});
        },
        () => {
            // image has been published
            uploadMarker(draftDeleteMarker, item, setItem);
            setItem(item, {
                url: "",
                path: ""
            });
        });
}

export async function publishDraftImages(userId, locationId) {
    // delete draft markers and any deleted files they points to
    return deletePrefix(getDraftMarkerPrefix(userId, locationId), MarkerType.DELETE);
}

export async function discardDraftImages(userId, locationId) {
    // delete draft markers and any created files they points to
    return deletePrefix(getDraftMarkerPrefix(userId, locationId), MarkerType.CREATE);
}

async function deletePrefix(prefix, markerTypeToDelete) {
    let storageRef = firestorage.ref();
    var listRef = storageRef.child(prefix);
    var promises = [];
    listRef.listAll()
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                promises.push(deletePrefix(folderRef.fullPath, markerTypeToDelete));
            });
            res.items.forEach((itemRef) => {
                if (markerTypeToDelete && itemRef.name.endsWith(markerTypeToDelete)) {
                    let markerPointerDestination = getDraftMarkerDestination(itemRef.fullPath, markerTypeToDelete);
                    promises.push(deleteFile(markerPointerDestination));
                }
                promises.push(itemRef.delete().catch((error) => {
                    console.log("error deleting images", error);
                }));
            });
        }).catch((error) => {
            console.log("error deleting images", error);
        });
    return Promise.all(promises);
}

async function deleteFile(path) {
    let storageRef = firestorage.ref(path);
    return storageRef.delete().catch((error) => {
        console.log("error deleting images", error);
    });
}

async function ifExists(path, thenFn, elseFn) {
    let storageRef = firestorage.ref();
    return storageRef.child(path).getDownloadURL().then(thenFn, elseFn);
}

export async function deleteItemImage(item) {
    if (!item.basicInfo?.image?.path) return Promise.resolve();
    return deleteDraftImage(item.basicInfo.image.path, item, () => { });
}

export async function deleteSectionImage(section) {
    if (!section.basicInfo?.image?.path) return Promise.resolve();
    return deleteDraftImage(section.basicInfo.image.path, section, () => { });
}

export async function deleteMenuImage(menu) {
    if (!menu.basicInfo?.image?.path) return Promise.resolve();
    return deleteDraftImage(menu.basicInfo.image.path, menu, () => { });
}

export async function deleteLocationImage(location) {
    if (!location.logo?.path) return Promise.resolve();
    return deleteDraftImage(location.logo.path, location, () => { });
}

export async function deleteSectionChildImages(section) {
    return deleteSectionImage(section).then(() => {
        let promises = [];
        Object.entries(section.items).forEach(([id, item]) => promises.push(deleteItemImage(item)))
        return Promise.all(promises);
    });
}

export async function deleteMenuChildImages(menu) {
    return deleteMenuImage(menu).then(() => {
        let promises = [];
        Object.entries(menu.sections).forEach(([id, section]) => promises.push(deleteSectionChildImages(section)))
        return Promise.all(promises);
    });
}

export async function deleteAllLocationImages(userId, locationId) {
    return Promise.all([
        deletePrefix(getDraftMarkerPrefix(userId, locationId), null),
        deletePrefix(getImagePrefix(userId, locationId), null)
    ]);
}
import React from "react"

import {Paper, Tooltip} from "@material-ui/core"
import {Button, Grid} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: 20
    },
    input: {
        display: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    uploadButton: {
        fontSize: "small"
    },
}));

export default function ImageManager(props) {
    const classes = useStyles();

    const {imageURL, updateImage, deleteImage} = props

    const uploadImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader()
            let imgFile = e.target.files[0]

            reader.onload = (e) => {
                let imgURL = e.target.result
                updateImage(imgFile, imgURL)
            }

            reader.readAsDataURL(imgFile)
        }
    }

    return (
        <Grid container space={5} direction="column">
            <Grid item className={classes.margin}>
                {
                    <Paper className={classes.paper} elevation={3}>
                        {
                            imageURL?
                                <Grid container item justify="center">
                                    <Grid item xs={12}>
                                        <img alt="complex" src={imageURL} style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%'
                                        }}/>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Delete Image" arrow interactive>
                                            <IconButton size="large">
                                                <HighlightOffIcon fontSize="inherit" color="secondary" onClick={deleteImage}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                :
                                <label htmlFor="contained-button-file" className={classes.uploadLabel}>
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={uploadImage}
                                    />
                                    <Button
                                        component="span"
                                        startIcon={<CloudUploadIcon color="primary" fontSize="large"/>}
                                        className={classes.uploadButton}
                                        fullWidth
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                        }
                    </Paper>
                }
            </Grid>
        </Grid>
    )
}
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, Radio, Slide, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { Checkbox, RadioGroup, TextField } from "formik-material-ui";
import { getAllTags, toMatchableLocation } from '../util/search';
import { Dietary, Spicy } from '../util/consts';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    gridItem: {
        width: "100%",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchButton({ setViewAllCallback, setLocationCallback, locationBody, viewAll }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [selectedDietaryList, setSelectedDietaryList] = React.useState([]);
    const [selectedSpicyList, setSelectedSpicyList] = React.useState([]);
    /* eslint no-unused-vars : "off" */
    const [selectedViewAll, setSelectedViewAll] = React.useState(false);
    const [searchCriteriaExist, setSearchCriteriaExist] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSetLocation = (location) => setLocationCallback(location);
    const handleSetViewAll = (viewAll) => setViewAllCallback(viewAll);

    const handleSearchSubmit = (data) => {
        let newSearchText = data.search;
        let newSelectedViewAll = (data.selectedViewAll === 'true');
        let newSelectedDietaryList = data.selectedDietaryList;
        let newSelectedSpicyList = data.selectedSpicyList;
        
        setSearchText(newSearchText);
        setSelectedViewAll(newSelectedViewAll);
        setSelectedDietaryList(newSelectedDietaryList);
        setSelectedSpicyList(newSelectedSpicyList);
        setSearchCriteriaExist(newSearchText !== "" || newSelectedDietaryList.length !== 0 ||
                               newSelectedSpicyList.length !== 0 || newSelectedViewAll !== true);

        handleSetViewAll(newSelectedViewAll);
        handleSetLocation(toMatchableLocation(locationBody, newSearchText, getSelectedTagList({
            dietaryList: newSelectedDietaryList,
            spicyList: newSelectedSpicyList
        })));
        setOpen(false);
    }

    const handleSearchReset = () => {
        let newSearchText = "";
        let newSelectedViewAll = true;
        let newSelectedDietaryList = [];
        let newSelectedSpicyList = [];
        
        setSearchText(newSearchText);
        setSelectedViewAll(newSelectedViewAll);
        setSelectedDietaryList(newSelectedDietaryList);
        setSelectedSpicyList(newSelectedSpicyList);
        setSearchCriteriaExist(false);

        handleSetViewAll(newSelectedViewAll);
        handleSetLocation(toMatchableLocation(locationBody, newSearchText, getSelectedTagList({
            dietaryList: newSelectedDietaryList,
            spicyList: newSelectedSpicyList
        })));
        setOpen(false);
    }

    const getSelectedTagList = ({ dietaryList = selectedDietaryList, spicyList = selectedSpicyList }) => {
        return dietaryList.concat(spicyList);
    }

    const dietaryOptions = Dietary.filter(dietaryOption => {
        let tags = getAllTags(locationBody)
        return tags.has(dietaryOption.toLowerCase());
    });

    const spicyOptions = Spicy.filter(spicyOption => {
        return getAllTags(locationBody).has(spicyOption.toLowerCase());
    });

    return (
        <div>
            <Fab
                color={searchCriteriaExist ? "primary" : "grey"}
                size="large"
                className={classes.fab}
                onClick={handleClickOpen}
            >
                <SearchIcon
                    fontSize="large"
                />
            </Fab>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <Formik
                    initialValues={{
                        search: searchText,
                        selectedViewAll: `${viewAll}`,
                        selectedDietaryList: selectedDietaryList,
                        selectedSpicyList: selectedSpicyList
                    }}
                    onSubmit={handleSearchSubmit}
                >
                    {props => {
                        return (
                            <Form className={classes.form}>
                                <DialogTitle id="form-dialog-title">Search</DialogTitle>
                                <DialogContent dividers={true}>
                                    <Grid container alignItems='flex-start' spacing={1} direction='column' justify="space-evenly" >
                                        <Grid item className={classes.gridItem}>
                                            <Field
                                                component={TextField}
                                                name="search"
                                                label="Search"
                                                type="search"
                                                fullWidth
                                                className={classes.searchInput}
                                            />
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <Typography variant="overline" color="textSecondary" gutterBottom>
                                                Show Menu Items
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <Grid container alignItems='flex-start' spacing={1} direction='column'>
                                                <Field component={RadioGroup} name="selectedViewAll">

                                                    <Grid item className={classes.gridItem}>
                                                        <FormControlLabel
                                                            value="false"
                                                            control={<Radio color='primary' />}
                                                            label="Available Now"
                                                        />
                                                    </Grid>
                                                    <Grid item justify='flex-start' className={classes.gridItem}>
                                                        <FormControlLabel
                                                            value="true"
                                                            control={<Radio color='primary' />}
                                                            label="Full Menu"
                                                        />
                                                    </Grid>
                                                </Field>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <Typography variant="overline" color="textSecondary" gutterBottom>
                                                {dietaryOptions.length !== 0 ? "Dietary Filter" : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <Grid container alignItems='flex-start' spacing={1} direction='column'>
                                                {dietaryOptions.map(dietaryOption => {
                                                    return (<Grid item className={classes.gridItem}>
                                                        <label>
                                                            <Field component={Checkbox}
                                                                name="selectedDietaryList"
                                                                color='primary'
                                                                value={dietaryOption.toLowerCase()}
                                                                checked={props.values.selectedDietaryList.indexOf(dietaryOption.toLowerCase()) > -1}
                                                            />
                                                            <Typography variant="overline" color="textPrimary">
                                                                {dietaryOption}
                                                            </Typography>
                                                        </label>
                                                    </Grid>);
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <Typography variant="overline" color="textSecondary" gutterBottom>
                                                {spicyOptions.length !== 0 ? "Spice Level Filter" : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <Grid container alignItems='flex-start' spacing={1} direction='column'>
                                                {spicyOptions.map(spicyOption => {
                                                    return (<Grid item className={classes.gridItem}>
                                                        <label>
                                                            <Field component={Checkbox}
                                                                name="selectedSpicyList"
                                                                color='primary'
                                                                value={spicyOption.toLowerCase()}
                                                                checked={props.values.selectedSpicyList.indexOf(spicyOption.toLowerCase()) > -1}
                                                            />
                                                            <Typography variant="overline" color="textPrimary">
                                                                {spicyOption}
                                                            </Typography>
                                                        </label>
                                                    </Grid>);
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleSearchReset} color="primary">
                                        Clear
                                    </Button>
                                    <Button type="submit" color="primary">
                                        Search
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        </div >
    )
}

import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Admin from './pages/Admin';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {ViewportProvider} from "./components/admin/UseViewport";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";

import './firebaseui-styling.global.css'; // Import globally.

const TERMS_OF_SERVICE_UPDATE_DATE  = "August 16, 2021"
const PRIVACY_POLICY_UPDATE_DATE    = "August 16, 2021"

function App() {
    return (
        <Router>
            <ViewportProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div>
                        <Switch>
                            <Route exact path="/" component={() => { 
                                    window.location.href = '/home/'; 
                                    return null;
                            }}/>
                            <Route path="/agreements/termsOfService" render={(props) => (
                                <TermsOfService {...props} lastUpdated={TERMS_OF_SERVICE_UPDATE_DATE} />
                            )}/>
                            <Route path="/agreements/privacyPolicy" render={(props) => (
                                <PrivacyPolicy {...props} lastUpdated={PRIVACY_POLICY_UPDATE_DATE} />
                            )}/>
                            <Route path="/admin" component={Admin} />
                            <Route exact path="/:locationAlias/:locationType?" component={Home}/>
                        </Switch>
                    </div>
                </MuiPickersUtilsProvider>
            </ViewportProvider>
        </Router>
    );
}

export default App;

import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Box, Typography, Tab, Tabs, Toolbar, Grid,
    CardMedia, Card, CardContent, Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Menu from './Menu';
import { isEntityAvailable, isEntityVisible } from '../util/visibility';
import EaterFooter from './EaterFooter';
import ScrollToTop from "./ScrollToTop";
import SearchButton from './SearchButton';
import { appleTabsStylesHook } from '@mui-treasury/styles/tabs';
import { useWideCardMediaStyles } from '@mui-treasury/styles/cardMedia/wide';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '500px',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    allMenus: {
        marginTop: 0,
    },
    tabPanel: {
        flexGrow: 1,
        width: '100%',
    },
    logo: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    title: {
        verticalAlign: 'middle',
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: '100%',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    searchTags: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.3),
        },
    },
    progress: {
        position: "fixed",
        overflow: "show",
        margin: "auto",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "50px",
        height: "50px"
    },
    filterChipListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
    },
    filterChipList: {
        // flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formItem: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    bannerCard: {
        minWidth: 320,
        position: 'relative',
        boxShadow: 'none',
    },
    bannerCardMain: {
        overflow: 'hidden',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    scrollBtn: {
        display: 'flex',
        justifyContent: 'flex-end',

    },
    searchBtn: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    a1: {
        color: theme.palette.primary.main,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function Location(props) {

    const classes = useStyles(props);
    const tabsStyles = appleTabsStylesHook.useTabs();
    const tabItemStyles = appleTabsStylesHook.useTabItem();
    const mediaStyles = useWideCardMediaStyles();

    const theme = useTheme();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [location, setLocation] = React.useState();
    const [viewAll, setViewAll] = React.useState(false);

    useEffect(() => {
        setLocation(props.locationBody);
        setViewAll(props.viewAll);
    }, [props.locationBody, props.viewAll])

    if (!location) {
        return <div />;
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const menuMap = new Map(Object.entries(location.menus));
    const menuArray = location.menuList;
    // remove hidden menus
    let now = new Date();
    menuMap.forEach((menu, menuId) => {
        if (!isEntityVisible(menu, now)) {
            menuMap.delete(menuId);
        }
    });
    // remove unavailable menus if filtering is enabled
    if (!viewAll) {
        menuMap.forEach((menu, menuId) => {
            if (!isEntityAvailable(menu, now)) {
                menuMap.delete(menuId);
            }
        });
    }
    if (!viewAll) {
        menuMap.forEach((menu, menuId) => {
            let now = new Date();
            if (!isEntityVisible(menu, now) || !isEntityAvailable(menu, now)) {
                menuMap.delete(menuId);
            }
        });
    }

    const getMenuLabelPrefix = (menu) => {
        return isEntityAvailable(menu, new Date()) ? "" : '[✖] ';
    }
    const menuTabs = menuArray.filter(menuId => menuMap.get(menuId) != null)
        .map((menuId, index) => {
            const menu = menuMap.get(menuId);
            return (
                <Tab label={getMenuLabelPrefix(menu) + menu.basicInfo.name} {...a11yProps(index)} classes={tabItemStyles} />
            );
        });

    //Add Edit code
    //props.editMode && menuTabs.push(<Tab label="Add Menu" id="editTab" {...a11yProps(menuTabs.length-1)} />)

    const menuPanels = menuArray.filter(menuId => menuMap.get(menuId) != null)
        .map((menuId, index) => {
            const menu = menuMap.get(menuId);
            return (
                <TabPanel className={classes.tabPanel} value={selectedTab} index={index} dir={theme.direction}>
                    <Menu key={menuId} menuId={menuId} menuBody={menu} />
                </TabPanel>
            );
        });

    return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center">
                <Grid item style={{ width: "100%" }}>
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Card elevation={0} className={classes.bannerCard}>
                            {location.logo.url &&
                                <CardMedia
                                    classes={mediaStyles}
                                    image={location.logo.url}
                                    title="Restaurant banner"
                                />
                            }
                            <CardContent >
                                <Typography gutterBottom variant="h4" component="h2" align="center">
                                    {location.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Divider />
            </Grid>
            <Grid container justify="center" alignItems="center" alignContent="center">
                <Grid item style={{ width: "100%" }} >
                    <Toolbar variant="dense" >
                        <Tabs style={{ width: "100%" }}
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="on"
                            aria-label="full width tabs example"
                            classes={tabsStyles}
                            centered
                        >

                            {menuTabs}

                        </Tabs>
                    </Toolbar>
                </Grid>
            </Grid>
            <div className={classes.allMenus}>
                {menuPanels}
            </div>

            <div className={classes.scrollBtn}>
                <ScrollToTop />
            </div>
            <div className={classes.searchBtn}>
                <SearchButton
                    locationBody={props.locationBody}
                    setLocationCallback={(location)=>setLocation(location)}
                    setViewAllCallback={(viewAll)=>setViewAll(viewAll)}
                    viewAll={viewAll} />
            </div>
            <EaterFooter locationBody={props.locationBody} />
        </div>
    );
}


import React from "react"

import {Dialog, DialogContent} from "@material-ui/core";

import DialogTitle from "../DialogTitle";
import LocationCreator from "./LocationCreator";

export default function LocationEditor(props) {
    let {docIds, location, editLocation, openLocationEditor, setLocationEditorOpen} = props

    const handleLocationEditorClose = () => {
        setLocationEditorOpen(false)
    }

    return(
        <div>
            <Dialog
                open={openLocationEditor}
                onClose={handleLocationEditorClose}
                fullWidth={true}
                maxWidth="md"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle onClose={handleLocationEditorClose}>
                    Location Editor
                </DialogTitle>
                <DialogContent dividers>
                    <LocationCreator
                        docIds = {docIds}
                        location = {location}
                        saveLocation = {editLocation}
                        setDisplayLocCreator = {setLocationEditorOpen}
                    >

                    </LocationCreator>
                </DialogContent>
            </Dialog>
        </div>
    )
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import {Switch} from "formik-material-ui";
import {FormControlLabel} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {Field, Form, Formik} from "formik";
import { DatePicker } from 'formik-material-ui-pickers';
import {HideValidationSchema} from "../../util/validation";
import Typography from "@material-ui/core/Typography";
const DialogMessages = {
    Location: "This will hide the location and all of its menus",
    Menu: "This will hide the menu and all of its sections",
    Section: "This will hide section and all of its items",
}


export default function HideDialog(props) {
    const {itemType, itemName, hideItem, openDialog, handleDialogClose} = props

    const onSubmit = data => {
        const visibility = {
            hidden: true,
            hiddenUntil: data.hideUntil? data.unhideDate.toISOString(): null
        }
        switch (itemType) {
            case "Location":
                hideItem(visibility)
                break
            case "Menu":
                hideItem(visibility)
                break
            case "Section":
                hideItem(visibility)
                break
            case "Item":
                hideItem(visibility)
                break
            default:
                console.error("unsupported item type: ", itemType)
        }

        handleDialogClose()
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >

                <Formik
                    initialValues={{
                        hideUntil: false,
                        unhideDate: new Date()
                    }}
                    onSubmit={onSubmit}
                    validationSchema={HideValidationSchema}
                >
                    {formProps => {
                        return (
                            <Form>
                                <DialogTitle>{`Hide ${itemType}`}</DialogTitle>
                                <Divider/>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    label={<Typography variant="subtitle2">Hide Until</Typography>}
                                                    control={
                                                        <Field
                                                            component={Switch}
                                                            name="hideUntil"
                                                            color="primary"
                                                            size="small"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5}>
                                                <Field
                                                    component={DatePicker}
                                                    name="unhideDate"
                                                    disabled={!formProps.values.hideUntil}
                                                    format="MM/dd/yyyy"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="column">
                                            <Grid item>
                                                <DialogContentText variant="subtitle2">
                                                    Are you sure you want to hide {itemType} {itemName}?
                                                </DialogContentText>
                                            </Grid>
                                            <Grid item>
                                                <DialogContentText  variant="subtitle2">
                                                    {DialogMessages[itemType]}
                                                </DialogContentText>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container justify="space-evenly">
                                        <Grid item>
                                            <Button color="primary" type="submit">
                                                Hide
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleDialogClose} color="primary">
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
        </div>
    );
}

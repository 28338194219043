import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import Item from './Item';
import { isEntityVisible } from '../util/visibility';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
        margin: `5px 0 0 ${theme.spacing(9)}px`,
    }
}));

export default function Section(props) {
    const classes = useStyles();

    const itemMap = new Map(Object.entries(props.sectionBody.items));
    const itemArray = props.sectionBody.itemList;
    // remove hidden items
    let now = new Date();
    itemMap.forEach((item, itemId) => {
        if (!isEntityVisible(item, now)) {
            itemMap.delete(itemId);
        }
    });
    const itemComponents = itemArray.filter(itemId => itemMap.get(itemId) != null)
        .map((itemId, index) => {
            const item = itemMap.get(itemId);
            return (
                <Item key={itemId} itemId={itemId} itemBody={item} />
            );
        });

    return (
        <List className={classes.root}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar src={props.sectionBody.basicInfo.image.url}>
                        <RestaurantIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.sectionBody.basicInfo.name}
                    primaryTypographyProps={{ variant: "h6" }} />
            </ListItem>
            <Divider className={classes.dividerFullWidth} />
            {itemComponents}
        </List>
    );
}